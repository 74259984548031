export const StructureImages = [
    require('../assets/Structure/banner_image/1.jpg'),
    require('../assets/Structure/banner_image//2.jpg'),
    require('../assets/Structure/banner_image/3.jpg')
];
export const StructureIcons = {
    hvac: require('../assets/Structure/banner_icon/227_Approvals_Secured.png'),
    water: require('../assets/Structure/banner_icon/692_Designs_Completed.png'),
    building: require('../assets/Structure/banner_icon/1070_Peer_Reviews.png')
};

export const OurExp = [
    require('../assets/Structure/Our_expertise_image/Commercial_and_Residential_Structures.png'),
    require('../assets/Structure/Our_expertise_image/Industrial_and_Infrastructure_Projects.png'),
    require('../assets/Structure/Our_expertise_image/Seismic_and_Load_Calculations.png'),
    require('../assets/Structure/Our_expertise_image/Structural_Analysis_and_Retrofitting.png')
];

export const StructureSampleImages = [
    require('../assets/Structure/sample_image/1.jpg'),
    require('../assets/Structure/sample_image/2.jpg'),
    require('../assets/Structure/sample_image/3.jpg')
];

