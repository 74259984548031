import React from "react"
import PlanTopSec from "../../components/plan-approval/PlanTopSec"
import Header from "../../components/Header"
import OurExpPlan from "../../components/plan-approval/OurExptForPlan"
import HowItWorksPlan from "../../components/plan-approval/HIWforPlan"
import WhyChooseUsPlan from "../../components/plan-approval/WCUplan"
import CustomerSaysOfPlan from "../../components/plan-approval/custSaysforPlan"
import PlanFaq from "../../components/plan-approval/FAQforPlan"
import { ProjectLocationsforPlan } from "../../components/plan-approval/cites"


const PlanApprovalPage = () => {
    return (
        <>
            <Header />
            <PlanTopSec />
            <OurExpPlan/>
            <HowItWorksPlan/>
            <ProjectLocationsforPlan/>
            <WhyChooseUsPlan/>
            <CustomerSaysOfPlan/>
            <PlanFaq/>
        </>
    )
}
export default PlanApprovalPage