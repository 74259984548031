import React from 'react';
import FaqSection from '../UI/FAQsection';

const StructreFaq = () => {
  const faqs = [
    {
      question: "What is structural design, and why is it important?",
      answer: "Structural design ensures the stability, safety, and durability of a building or infrastructure."
    },
    {
      question: "Do you provide structural analysis and load calculations?",
      answer: "Yes, we conduct in-depth structural analysis, including seismic and load calculations, for safe designs."
    },
    {
      question: "Can you retrofit or strengthen existing structures?",
      answer: "Absolutely! We provide retrofitting solutions to enhance the safety and longevity of old buildings."
    },
    {
      question: "Do your designs comply with local building codes?",
      answer: "Yes, all our designs meet the required regulatory codes and industry standards."
    },
    {
      question: "How long does it take to complete a structural design?",
      answer: "It depends on the project size, but we ensure timely delivery with precision."
    }
  ];

  return <FaqSection faqData={faqs} />;
};

export default StructreFaq;