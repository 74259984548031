import React from "react";
import CustomerSays from "../UI/CustomerSays";

const CustomerSaysOfPlan = () => {
    const testimonials = [
        { name: "Arjun Mehta", quote: "Fast approvals with complete legal compliance. Super smooth process!" },
        { name: "Deepika Singh", quote: "Hassle-free and professional. Got my plan approved quickly!" },
        { name: "Rahul Joshi", quote: "Expert guidance through every step of the approval process." },
        { name: "Anita Menon", quote: "They handled all documentation, making approval stress-free." },
        { name: "Sohail Khan", quote: "Great team! Quick approvals without any unnecessary delays." }
    ];

    return <CustomerSays testimonials={testimonials} />;
};

export default CustomerSaysOfPlan;
