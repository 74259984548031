import React from "react";
import { motion } from "framer-motion";
import WhyChooseUs from "../UI/WhyChooseUs";

const WhyChooseUsStructure = () => {
  const features = [
    {
      icon: require("../../assets/Structure/why_choose_us/Realistic_3D_Visualization.png"),
      title: "Realistic 3D Visualization",
      description: "Bring ideas to life with highly accurate 3D renderings, showcasing the project with stunning realism.",
    },
    {
      icon: require("../../assets/Structure/why_choose_us/Cost_Effective_Revisions.png"),
      title: "Cost-Effective Revisions",
      description:
        "Get unlimited design modifications without breaking the budget, ensuring optimal project outcomes.",
    },
 
    {
      icon: require("../../assets/Structure/why_choose_us/Interactive_Virtual_Tour.png"),
      title: "Interactive Virtual Tour",
      description: "Immerse yourself in a lifelike virtual tour, allowing real-time exploration of spaces before construction begins.",
    },
    {
      icon: require("../../assets/Structure/why_choose_us/Detailed_Interiors_and_Exteriors.png"),
      title: "Detailed Interiors & Exteriors",
      description: "Experience well-planned, high-quality interior and exterior designs with meticulous attention to detail.",
    },
    {
      icon: require("../../assets/Structure/why_choose_us/Marketing_and_Client_Presentations.png"),
      title: "Marketing & Client Presentations",
      description: "Enhance your project’s appeal with compelling visual presentations for better client engagement and sales.",
    },
 
  ];

  return (
   <>
   <WhyChooseUs features={features}/>
   </>
  );
};

export default WhyChooseUsStructure;
