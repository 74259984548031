import { useState, useEffect } from "react";
import { mepImages,mepIcons} from "../../constants/MepImages";
import React from "react";
import TopSection from "../UI/TopSection";





const MEPTopSec = () => {
    const structureData = {
        title: "Expert MEP Drawing Services – ",
        subtitle: "Precision & Performance",
        description: "At Design Elementary, we deliver smart, efficient, and high-performance MEP designs for seamless building functionality.",
        stats: [
          { icon: mepIcons.building, label: "Systems Installed", count: 550 },
          { icon: mepIcons.water, label: "Water Solutions", count: 400 },
          { icon: mepIcons.hvac, label: "HVAC Designs", count: 300 }
        ]
      };
  return (
    <><TopSection title={structureData.title} subtitle={structureData.subtitle} description={structureData.description} stats={structureData.stats} images={mepImages}/></>
  );
};

export default MEPTopSec;
