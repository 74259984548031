import React, { useState } from 'react';
import HowItWorks from '../UI/HowItsWorks';

const HowItWorksWalk = () => {
    

    const steps = [
        {
            number: 1,
            title: "Consultation & Project Brief",
            icon: require('../../assets/Walkthrough/HOW_IT_WORKS_icon/Consultation_and_Project_Brief.png'),
            description: ""
        },
        
        {
            number: 2,
            title: "3D Modeling  & Design",
            icon: require('../../assets/Walkthrough/HOW_IT_WORKS_icon/3D_Modeling_and_Design.png'),
            description: ""
        },
        
       
        {
            number: 3,
            title: "Rendering & Animation",
            icon: require('../../assets/Walkthrough/HOW_IT_WORKS_icon/Rendering_and_Animation.png'),
            description: ""
        },
        {
            number: 4,
            title: "Client Reviews & Modification",
            icon: require('../../assets/Walkthrough/HOW_IT_WORKS_icon/Client_Review_and_Modifications.png'),
            
            description: ""
        },
         
       
        {
            number: 5,
            title: "Final Delivery",
            icon: require('../../assets/Walkthrough/HOW_IT_WORKS_icon/Final_Delivery.png'),
            
            description: ""
        },
    ];


    return (
        <><HowItWorks steps={steps}/></>
    );
};

export default HowItWorksWalk;
