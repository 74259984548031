import React, { useState } from 'react';
import FaqSection from '../UI/FAQsection';

const WalkFaq = () => {


  const faqs = [
    {
      question: "What is a 3D walkthrough?",
      answer: "A 3D walkthrough is a virtual tour of your project, allowing you to experience it before construction begins."
    },
    {
      question: "How does a walkthrough help in project visualization?",
      answer: "It gives a realistic preview of interiors, exteriors, and architectural details, making design decisions easier."
    },
    {
      question: "Can I request changes after seeing the walkthrough?",
      answer: "Yes, modifications can be made based on your feedback before finalizing the design."
    },
    {
      question: "Is a walkthrough suitable for both residential and commercial projects?",
      answer: "Absolutely! We create walkthroughs for homes, offices, malls, and other properties."
    },
    {
      question: "What software do you use for walkthroughs?",
      answer: "We use industry-leading software to create high-quality, immersive 3D walkthroughs."
    }
  ];

  return <FaqSection faqData={faqs} />;
};

export default WalkFaq;