import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { StructureSampleImages } from "../../constants/Structure";

const StructureDesigns = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) =>
        prev === StructureSampleImages.length - 1 ? 0 : prev + 1
      );
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === StructureSampleImages.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? StructureSampleImages.length - 1 : prev - 1
    );
  };

  return (
    <div className="w-full py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-6">
        {/* Title */}
        <div className='max-w-[20rem] md:max-w-[28rem] mx-auto'>
                    <h2 className="text-4xl font-bold text-center mb-12">
                        <div className="box a green-variant mb-12 p-3">
                            <h1 className="text-2xl md:text-4xl text-center font-semibold text-gray-900">Structue Designs</h1>
                        </div>

                    </h2>
                </div>

        {/* Image Slider */}
        <div className="relative w-full aspect-[16/9] overflow-hidden rounded-3xl" style={{ height: "500px" }}>
          <AnimatePresence mode="wait">
            <motion.img
              key={currentSlide}
              src={StructureSampleImages[currentSlide]}
              alt={`MEP Design ${currentSlide + 1}`}
              className="w-full h-full object-contain bg-white rounded-3xl shadow-[0_15px_40px_rgba(0,0,0,0.4)]"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>

          {/* Navigation Arrows */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-3 rounded-full shadow-xl transition-all"
          >
            <svg
              className="w-7 h-7 text-gray-700"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-3 rounded-full shadow-xl transition-all"
          >
            <svg
              className="w-7 h-7 text-gray-700"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          {/* Dots Navigation */}
          <div className="absolute bottom-5 left-1/2 -translate-x-1/2 flex space-x-2">
            {StructureSampleImages.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  currentSlide === index
                    ? "bg-[#0e906f] w-10"
                    : "bg-gray-400 hover:bg-gray-600"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Thumbnail Preview */}
        <div className="flex justify-center gap-4 mt-8">
          {StructureSampleImages.map((img, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`relative rounded-xl overflow-hidden w-16 h-16 transition-all ${
                currentSlide === index
                  ? "ring-4 ring-[#0e906f] ring-offset-2 shadow-lg"
                  : "opacity-60 hover:opacity-100"
              }`}
            >
              <img
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StructureDesigns;
