import React, { useState } from 'react';
import HowItWorks from '../UI/HowItsWorks';

const HowItWorksElevation = () => {


    const steps = [
        {
            number: 1,
            title: "Consultation & Design Brief",
            icon: require('../../assets/3D/How_It_works/Consultation_and_Design_Brief.png'),
            description: "Schedule your project timeline"
        },
        
        {
            number: 2,
            title: "Strategic Planning",
            icon: require('../../assets/3D/How_It_works/Concept_Development.png'),
            description: "Detailed cost estimation and portfolio presentation"
        },
        
        {
            number: 3,
            title: "Rendering & Detailing",
            icon: require('../../assets/3D/How_It_works/Rendering_and_Detailing.png'),
            description: "Comprehensive MEP system design and documentation"
        },
        {
            number: 4,
            title: "Review & Modification",
            icon: require('../../assets/3D/How_It_works/Client_Review_and_Modifications.png'),
            description: "Initial discussion about your project requirements and scope"
        },
        {
            number: 5,
            title: "Final Delivery",
            icon: require('../../assets/3D/How_It_works/Final_Delivery.png'),
            description: "Quality check and project delivery"
        }
    ];


    return (
        <>
            <HowItWorks steps={steps} />
        </>
    );
};

export default HowItWorksElevation;
