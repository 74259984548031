import React from "react";
import CustomerSays from "../UI/CustomerSays";

const CustomerSaysOfMEP = () => {
  const testimonials = [
    { name: "Kiran Malhotra", quote: "Efficient MEP designs with seamless execution. Highly satisfied!" },
    { name: "Sunita Rao", quote: "Their team delivered energy-efficient and cost-effective MEP solutions." },
    { name: "Manish Gupta", quote: "Smooth coordination and compliance with regulations. Great work!" },
    { name: "Neha Bansal", quote: "The best MEP service I've used—practical and sustainable designs!" },
    { name: "Sandeep Pillai", quote: "Perfectly planned electrical and plumbing solutions. Zero complaints!" }
  ];

  return (
    <>
    <CustomerSays testimonials={testimonials}/>
    </>
  )
};

export default CustomerSaysOfMEP;
