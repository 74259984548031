import React from "react"
import TopSection from "../UI/TopSection";
import { ElevationImages ,ElevationIcons } from "../../constants/Elevation";

const TopSecElevation = () =>{
    const structureData = {
        title: "3D Elevation Services – Elevate",
        subtitle: "Your Design with Stunning Visuals",
        description: "At Design Elementary, we craft high-quality 3D elevations that bring your architectural vision to life with photorealistic precision.",
        stats: [
          { icon: ElevationIcons.Compliance, label: "Rendered Designs", count: 1500 },
          { icon: ElevationIcons.Approved, label: "Custom Elevations", count: 900 },
          { icon: ElevationIcons.Municipal, label: "Luxury Exteriors", count: 500 }
        ]
      };
  return (
    <><TopSection title={structureData.title} subtitle={structureData.subtitle} description={structureData.description} stats={structureData.stats} images={ElevationImages}/></>
  );
}
export default TopSecElevation