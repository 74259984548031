import React from "react";
import CustomerSays from "../UI/CustomerSays";

const CustomerSaysOfElevation = () => {
    const testimonials = [
        { name: "Ritika Kapoor", quote: "Stunning 3D elevation, brought my vision to life beautifully!" },
        { name: "Prashant Yadav", quote: "Perfect blend of aesthetics and functionality. Amazing work!" },
        { name: "Swati Saxena", quote: "The designs were modern, elegant, and just what I wanted!" },
        { name: "Anil Tiwari", quote: "They nailed the exterior look of my home. Truly impressive!" },
        { name: "Farah Hussain", quote: "Exceptional detailing and top-notch visualization!" }
    ];

    return <CustomerSays testimonials={testimonials} />;
};

export default CustomerSaysOfElevation;
