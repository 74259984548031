import React from 'react';
import { OurExp as ExpertiseImages } from '../../constants/walkthrough';
import OurExp from '../UI/OurExp';

const OurExpWalk = () => {
    const expertiseData = [
        {
            title: "Residential & Commercial Walkthroughs",
            image: ExpertiseImages[2]
        },
        {
            title: "Interior & Exterior 3D Views",
            image: ExpertiseImages[1]
        },

        {
            title: "Virtual Reality (VR) Integration",
            image: ExpertiseImages[3]
        },
        {
            title: "Customizable Design Options",
            image: ExpertiseImages[0]
        },
        
        
    ];

    return (
        <OurExp expertiseData={expertiseData}/>
    );
};

export default OurExpWalk;
