import { useState, useEffect } from "react";
import React from "react";

const Counter = ({ target }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    let start = 0;
    const duration = 2000;
    const increment = target / (duration / 30);
    const counter = setInterval(() => {
      start += increment;
      if (start >= target) {
        clearInterval(counter);
        setCount(target);
      } else {
        setCount(Math.floor(start));
      }
    }, 30);
    return () => clearInterval(counter);
  }, [target]);
  return <span className="font-bold text-lg sm:text-2xl text-gray-800">{count}+</span>;
};

const TopSecStructure = ({ title, subtitle, description, stats, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, [images]);
  
  // Check if we're on mobile using media query
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024); // 1024px is the lg breakpoint in Tailwind
    };
    
    // Check initially
    checkMobile();
    
    // Add event listener
    window.addEventListener('resize', checkMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  // Render different layouts based on screen size
  if (isMobile) {
    // Mobile Layout
    return (
      <div className="w-full md:mt-6">
        {/* Mobile image at the top */}
        <div className="w-full h-64 relative mb-2">
          <div
            className="absolute inset-0 bg-center bg-fit bg-no-repeat transition-opacity duration-1000"
            style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
          />
          <div className="absolute inset-0 bg-black opacity-10" />
        </div>
        
        {/* Content section */}
        <div className="w-full p-2">
          <div className="bg-white/90 rounded-lg p-6 shadow-md">
            <h1 className="text-2xl font-bold text-[#0e906f] mb-1 leading-tight">{title}</h1>
            <h2 className="text-2xl font-bold text-[#0e906f] mb-4 leading-tight">{subtitle}</h2>
            <p className="text-gray-700 text-sm mb-6 leading-relaxed">{description}</p>
            
            <div className="flex flex-wrap justify-between mb-6 gap-2">
              {stats.map((item, index) => (
                <div key={index} className="text-center w-[30%]">
                  <div className="flex justify-center items-center gap-2 mb-2">
                    <img src={item.icon} alt={item.label} className="h-6 w-6" />
                    <Counter target={item.count} />
                  </div>
                  <div className="text-[10px] text-gray-600 whitespace-pre-line">{item.label}</div>
                </div>
              ))}
            </div>
            
            <div className="space-y-3">
              <h3 className="text-lg font-semibold text-[#0e906f]">Get Free Consultation</h3>
              <div className="flex flex-col space-y-3">
                <div className="flex items-center">
                  <div className="flex items-center border-2 border-gray-200 rounded-md p-2 px-4 text-gray-600 text-sm font-medium w-16">
                    <span>+91</span>
                  </div>
                  <input
                    type="text"
                    placeholder="xxxxx xxxxx"
                    className="border-2 border-gray-200 rounded-md p-2 px-4 text-gray-600 text-sm font-medium w-full ml-2"
                  />
                </div>
                <button className="bg-[#0e906f] text-white py-2 px-6 rounded-md font-medium hover:bg-green-700 transition-colors">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Desktop Layout - Keep exactly as original
  return (
    <div className="relative flex flex-col lg:flex-row h-[80vh] w-full overflow-hidden mt-10">
      <div
        className="absolute inset-0 bg-center bg-cover bg-no-repeat transition-opacity duration-1000"
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      />
      <div className="absolute inset-0 bg-black opacity-10" />
      <div className="relative flex flex-col lg:flex-row w-full z-10">
        <div className="relative w-full lg:w-[60%] flex items-center justify-center p-4 sm:p-12">
          <div
            className="absolute inset-0 bg-white rounded-r-[28%] shadow-[0_0_40px_rgba(0,0,0,0.1)] hidden lg:block"
            style={{ clipPath: "ellipse(100% 75% at left center)" }}
          />
          <div className="absolute inset-0 bg-white/90 lg:hidden" />
          <div className="relative z-10 px-3 sm:px-8 py-4 sm:py-10 max-w-3xl">
            <h1 className="text-2xl sm:text-4xl font-bold text-[#0e906f] mb-1 leading-tight">{title}</h1>
            <h2 className="text-2xl sm:text-4xl font-bold text-[#0e906f] mb-4 sm:mb-6 leading-tight">{subtitle}</h2>
            <p className="text-gray-700 text-sm sm:text-lg mb-6 sm:mb-10 leading-relaxed">{description}</p>
            <div className="flex flex-wrap justify-between mb-6 sm:mb-12 gap-2 sm:gap-4">
              {stats.map((item, index) => (
                <div key={index} className="text-center w-[30%] sm:w-auto">
                  <div className="flex justify-center items-center gap-2 mb-2">
                    <img src={item.icon} alt={item.label} className="h-6 w-6 sm:h-10 sm:w-10" />
                    <Counter target={item.count} />
                  </div>
                  <div className="text-[10px] sm:text-sm text-gray-600 whitespace-pre-line">{item.label}</div>
                </div>
              ))}
            </div>
            <div className="space-y-3 sm:space-y-4">
              <h3 className="text-lg sm:text-xl font-semibold text-[#0e906f]">Get Free Consultation</h3>
              <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
                <div className="flex items-center border-2 border-gray-200 rounded-md p-2 px-4 text-gray-600 text-sm font-medium">
                  <span className="mr-2">+91</span>
                </div>
                <input
                  type="text"
                  placeholder="xxxxx xxxxx"
                  className="border-2 border-gray-200 rounded-md p-2 px-4 text-gray-600 text-sm font-medium w-full sm:w-auto"
                />
                <button className="bg-[#0e906f] text-white py-2 px-6 sm:px-8 rounded-md font-medium hover:bg-green-700 transition-colors">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[40%] hidden lg:block" />
      </div>
    </div>
  );
};

export default TopSecStructure;