import React from "react";
import WhyChooseUs from "../UI/WhyChooseUs";

const WhyChooseUsElevation = () => {
    const features = [
        {
            icon: require("../../assets/3D/why_choose_us/Customizable_Designs.png"),
            title: "Customizable Designs",
            description:
                "",
        },
        {
            icon: require("../../assets/3D/why_choose_us/Fast_Turnaround_Time.png"),
            title: "Fast Turnaround Time",
            description: "",
        },
        {
            icon: require("../../assets/3D/why_choose_us/High_Definition_Realism.png"),
            title: "High-Definition Realism",
            description: "",
        },
        {
            icon: require("../../assets/3D/why_choose_us/Perfect_for_Approvals_and_Marketing.png"),
            title: "Perfect for Approvals & Marketing",
            description: "",
        },
        {
            icon: require("../../assets/3D/why_choose_us/Seamless_Integration.png"),
            title: "Seamless Integration",
            description: "",
        },
    ];

    return (
        <>
            <WhyChooseUs features={features} />
        </>
    );
};

export default WhyChooseUsElevation;
