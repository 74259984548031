import React from "react"
import Header from "../../components/Header"
import MEPDesigns from "../../components/MEP/MEPDesigns"
import CustomerSays from '../../components/MEP/CoustomerSays'
import MEPFaq from '../../components/MEP/MEPFaq'
import WhyChooseUsMEP from "../../components/MEP/WhyChooseUs"
import HowItWorksMEP from "../../components/MEP/HowitWorks"
import MEPTopSec from "../../components/MEP/MEPTopSec"
import OurExpMEP from "../../components/MEP/OurExp"


const MEPpage = () => {
    return <>
        <Header />
        <MEPTopSec />
        <MEPDesigns/>
        <OurExpMEP />
        <HowItWorksMEP />
        <WhyChooseUsMEP />
        <CustomerSays />
        <MEPFaq />
    </>
}
export default MEPpage