export const planImages = [
    require('../assets/PlanApproval/banner_image/1.jpg'),
    require('../assets/PlanApproval/banner_image/2.jpg'),
    require('../assets/PlanApproval/banner_image/3.jpg')
];
export const planIcons = {
    Municipal: require('../assets/PlanApproval/banner_icon/250_Municipal_Clearances.png'),
    Approved: require('../assets/PlanApproval/banner_icon/300_Approved_Projects.png'),
    Compliance: require('../assets/PlanApproval/banner_icon/900_Compliance_Checks.png')
};

export const OurExp = [
    require('../assets/PlanApproval/Our_expertise_image/Document_and_Drawing_Verification.png'),
    require('../assets/PlanApproval/Our_expertise_image/Legal_and_Safety_Regulations.png'),
    require('../assets/PlanApproval/Our_expertise_image/Municipal_and_Government_Compliance.png'),
    require('../assets/PlanApproval/Our_expertise_image/Residential_and_Commercial_Plan_Approvals.png')
];


export const ExecutionCities = [
    {
        city: "Bangalore",
        address: "",
        image: require("../assets/execution-cities/bangalore.png")
    },

    {
        city: "Kolkata",
        address: "",
        image: require("../assets/execution-cities/kolkata.png")
    },

    {
        city: "Hyderabad",
        address: "",
        image: require("../assets/execution-cities/hyderabad.png")
    },
    {
        city: "Ranchi",
        address: "",
        image: require("../assets/execution-cities/ranchi.png")
    },


    {
        city: "Chennai",
        address: "",
        image: require("../assets/execution-cities/hyderabad.png")
    },

    {
        city: "Patna",
        address: "",
        image: require("../assets/execution-cities/kolkata.png")
    },


];




