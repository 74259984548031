import React from 'react';
import HowItWorks from '../UI/HowItsWorks';

const HowItWorksMEP = () => {


    const steps = [
        {
            number: 1,
            title: "Consult with Engineer",
            icon: require('../../assets/MEP/HOW_IT_WORKS_icon/Consultation_with_Engineer.png'),
            description: "Initial discussion about your project requirements and scope"
        },
        {
            number: 2,
            title: "Get Quote & Review Our Work",
            icon: require('../../assets/MEP/HOW_IT_WORKS_icon/Get_Quote_and_Review_Our_Work.png'),
            description: "Detailed cost estimation and portfolio presentation"
        },
        {
            number: 3,
            title: "Book Your Slot",
            icon: require('../../assets/MEP/HOW_IT_WORKS_icon/Book_Your_Slot.png'),
            description: "Schedule your project timeline"
        },
        {
            number: 4,
            title: "MEP Drafting & Coordination",
            icon: require('../../assets/MEP/HOW_IT_WORKS_icon/MEP_Drafting_and_Coordination.png'),
            description: "Comprehensive MEP system design and documentation"
        },
        {
            number: 5,
            title: "Final Review & Handover",
            icon: require('../../assets/MEP/HOW_IT_WORKS_icon/Final_Review_and_Handover.png'),
            description: "Quality check and project delivery"
        }
    ];


    return (
        <>
            <HowItWorks steps={steps} />
        </>
    );
};

export default HowItWorksMEP;
