import React from 'react';
import { OurExp as ExpertiseImages } from '../../constants/MepImages';
import OurExp from '../UI/OurExp';


const OurExpMEP = () => {
    const expertiseData = [
        {
            title: "HVAC & Ventilation Systems",
            image: ExpertiseImages[2]
        },
        {
            title: "Electrical Layouts",
            image: ExpertiseImages[0]
        },
        {
            title: "Plumbing & Drainage Systems",
            image: ExpertiseImages[3]
        },
        {
            title: "Fire Safety & Protection Systems",
            image: ExpertiseImages[1]
        }
    ];

    return (
        <OurExp expertiseData={expertiseData}/>
    );
};

export default OurExpMEP;
