import React from "react";
import { StructureImages, StructureIcons } from "../../constants/Structure";
import TopSection from "../UI/TopSection";

const StructureTopSec = () => {
    const structureData = {
        title: "Expert Structural Design Services – ",
        subtitle: "Build with Confidence",
        description: "At DesignElementary, we craft safe, efficient, and high-standard structural designs.",
        stats: [
          { icon: StructureIcons.building, label: "Designs Completed", count: 692 },
          { icon: StructureIcons.water, label: "Approvals Secured", count: 227 },
          { icon: StructureIcons.hvac, label: "Peer Reviews", count: 1070 }
        ]
      };
  return (
    <div className="mt-24"><TopSection  title={structureData.title} subtitle={structureData.subtitle} description={structureData.description} stats={structureData.stats} images={StructureImages}/></div>
  );
};

export default StructureTopSec;
