// TemplatePage.tsx
import React from 'react';
import HowItWorksSection from '../../components/template/HowItWorks';
import ReviewsSection from '../../components/template/Reviews';
import FAQSection from '../../components/template/FAQSection';
import NumbersSection from '../../components/template/NumbersSection';
// import HomesSection from '../../components/template/Homes';
import KeyFeatures from '../../components/template/KeyFeatures';
import InteriorOfferComponent from '../../components/template/TopSection';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import data from '../../constants/LandscapeData';

import HowItWorks from '../../components/UI/HowItsWorks';
import FaqSection from '../../components/UI/FAQsection';
import CustomerSays from '../../components/UI/CustomerSays';

const Landscape = () => {
  const { features, faqs, numbers, stepsData,stepData, topSection,review } = data;
  return (
    <>
     <Header />
      <InteriorOfferComponent
        headingText={topSection.headingText}
        highlightedText={topSection.highlightedText}
        headingText2={topSection.headingText2}
        subText={topSection.subText}
        video={topSection.video}
        service='Landscape'
      />

      <KeyFeatures
        features={features}
        title="Key Features"
      />

      <NumbersSection
        title="Let Our Numbers Do the Talking!"
        numbers={numbers}
      />

      <HowItWorks
        steps={stepData}
      />

      <CustomerSays
        testimonials={review}
      />

      <FaqSection
        faqData={faqs}
      />

      {/* <HomesSection
        cardData={homes}
      /> */}

      <Footer />
    </>
  );
};

export default Landscape;
