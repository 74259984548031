import React, { useState } from 'react';
import FaqSection from '../UI/FAQsection';

const ElevationFaq = () => {
  // const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What is a 3D elevation?",
      answer: "A 3D elevation is a detailed visual representation of a building’s exterior design."
    },
    {
      question: "Why is a 3D elevation important for my project?",
      answer: "It helps visualize the final look of the building and ensures the design matches your expectations."
    },
    {
      question: "Can I customize my 3D elevation?",
      answer: "Yes, we offer tailored 3D elevation designs based on your preferences and requirements."
    },
    {
      question: "Do you provide different styles of 3D elevations?",
      answer: "Yes, we design contemporary, classical, and modern elevations to match your aesthetic."
    },
    {
      question: "How long does it take to create a 3D elevation?",
      answer: "It depends on the complexity, but we ensure high-quality designs within a reasonable timeframe."
    }
  ];

  return (
    <FaqSection faqData={faqs}/>
  );
};

export default ElevationFaq;
