import React from "react";
import WhyChooseUs from "../UI/WhyChooseUs";

const WhyChooseUsPlan = () => {
    const features = [
      
        {
            icon: require("../../assets/PlanApproval/why_choose_us/End_to_End_Support.png"),
            title: "End-to-End Support",
            description: "",
        },

        {
            icon: require("../../assets/PlanApproval/why_choose_us/Expert_Consultation.png"),
            title: "Expert  Consultation",
            description: "",
        },
        {
            icon: require("../../assets/PlanApproval/why_choose_us/Code_Compliance.png"),
            title: "Code Compliance",
            description:
                "",
        },
        
        {
            icon: require("../../assets/PlanApproval/why_choose_us/Quick_Documentation.png"),
            title: "Quick Documentation",
            description: "",
        },
        {
            icon: require("../../assets/PlanApproval/why_choose_us/Regulatory_Liaison.png"),
            title: "Regulatory Liaison",
            description: "",
        },
    ];

    return (
        <>
            <WhyChooseUs features={features} />
        </>
    );
};

export default WhyChooseUsPlan;
