import React from "react";
import { motion } from "framer-motion";
import WhyChooseUs from "../UI/WhyChooseUs";

const WhyChooseUsMEP = () => {
  const features = [
    {
      icon: require("../../assets/MEP/why_choose_us/Comprehensive_MEP_Planning.png"),
      title: "Comprehensive MEP Planning",
      description:
        "Integrated planning for all mechanical, electrical, and plumbing systems.",
    },
    {
      icon: require("../../assets/MEP/why_choose_us/Energy_Efficient_Designs.png"),
      title: "Energy-Efficient Designs",
      description: "Sustainable solutions that optimize resource utilization.",
    },
    {
      icon: require("../../assets/MEP/why_choose_us/Compliance_with_Standards.png"),
      title: "Compliance with Standards",
      description: "Adherence to international and local building codes.",
    },
    {
      icon: require("../../assets/MEP/why_choose_us/Quick_Turnaround_Time.png"),
      title: "Quick Turnaround Time",
      description: "Efficient delivery without compromising quality.",
    },
    {
      icon: require("../../assets/MEP/why_choose_us/Advanced_Drafting_Technology.png"),
      title: "Advanced Drafting Technology",
      description: "State-of-the-art tools for precise documentation.",
    },
  ];

  return (
    <><WhyChooseUs features={features}/></>
  );
};

export default WhyChooseUsMEP;
