

  export const graphics = [
    require("../assets/offerings/interior/1.jpg"),
    require("../assets/offerings/interior/2.jpg"),
    require("../assets/offerings/interior/3.jpg"),
    require("../assets/offerings/interior/4.jpg"),
    require("../assets/offerings/interior/5.jpg"),
    require("../assets/offerings/interior/6.jpg"),
    require("../assets/offerings/interior/7.jpg"),
    require("../assets/offerings/interior/8.jpg"),
    require("../assets/offerings/interior/9.jpg"),
    require("../assets/offerings/interior/10.jpg"),
    require("../assets/offerings/interior/11.jpg"),
    require("../assets/offerings/interior/12.jpg"),
    require("../assets/offerings/interior/13.jpg"),
    require("../assets/offerings/interior/offer.png"),
    require("../assets/offerings/interior/line.png"),
  ];
  
  export const whatWeOffer = [
     {
      text : "END TO END DESIGN SOLUTION",
      image : require("../assets/offerings/interior/offer/1.webp"),
     },
     {
      text : "2D WORKING DRAWING",
      image : require("../assets/offerings/interior/offer/2.webp"),
     },
     {
      text : "BOQ & COSTING",
      image : require("../assets/offerings/interior/offer/3.webp"),
     },
     {
      text : "CUSTOM PRODUCT DESIGN",
      image : require("../assets/offerings/interior/offer/4.webp"),
     },
     {
      text : "360 DEGREE VIEW",
      image : require("../assets/offerings/interior/offer/5.webp"),
     },
     {
      text : "3D RENDERED DESIGN",
      image : require("../assets/offerings/interior/offer/6.webp"),
     },
  ]
  
  export const process = [
    {
      text: 'It all begins with dialogues about your ideas.',
      img: graphics[7],
    },
    {
      text: 'New ideas generation and brainstorming follows.',
      img: graphics[8],
    },
    {
      text: 'A concrete design plan is developed, helping to ensure there is actionability.',
      img: graphics[9],
    },
    {
      text: 'Meticulously we implement your design into reality.',
      img: graphics[10],
    },
    {
      text: 'Minor adjustments are made to make everything better.',
      img: graphics[11],
    },
  ]
  
  export const FAQs = [
    {
      "question": "What types of projects do you handle?",
      "answer": "We work on different kinds of projects such as housing and office interiors, renovations as well as new constructions. We are available to assist whether in need of a room makeover or doing an entire home redesign."
    },
    {
      "question": "How much do your design services cost?",
      "answer": "Our prices depend on the size and details of the project. After our first meeting, we provide you with a clear estimate for everything involved therein."
    },
    {
      "question": "Can you design within a specific budget?",
      "answer": "Yes we can operate within any budget however limited it may be at its lowest point. We help you come up with a design plan that suits your financing constraints and offers excellent results."
    },
    {
      "question": "How do you ensure the design matches my style?",
      "answer": "We start with a discussion to understand your style and needs. Then we make sure our designs are tailored to meet what pleases you personally in terms of tastes and preferences; this includes taking into consideration your lifestyle too."
    },
    {
      "question": "How do you choose materials and furniture for the project?",
      "answer": "The materials and furnishings are selected according to your style as well as practical requirements. We will provide you with alternatives and seek your advice on them before arriving at final choices."
    },
    {
      "question": "Do you facilitate setting up of the design?",
      "answer": "Yes, we supervise the whole installation process in order that it corresponds to a design concept. To achieve desired results, we cooperate with contractors and suppliers in this."
    }
  ]
  
  const custReviews = [
    {
      "name": "Ravi",
      "rating": 5,
      "review": "The design team beautifully transformed my space! I love how it turned out."
    },
    {
      "name": "Ayushi",
      "rating": 5,
      "review": "From the very first idea to the end, they exceeded all my expectations. Now, my home feels like a dream."
    },
    {
      "name": "Kritvaya",
      "rating": 4.8,
      "review": "Their creativity and attention to details are amazing. At last, I have got my dream house."
    },
    {
      "name": "Pritika",
      "rating": 5,
      "review": "They captured my style perfectly. Everything here is so fashionable and handy."
    }
  ]
  
  
  
export const InteriorOfferingsData = [
    {
      title: 'Bedroom',
      description: ' We give birth to exquisite areas that induce a peaceful rest.',
      imgmain: graphics[1],
      images: [
        require("../assets/offerings/interior/bedroom/1.webp"),
        require("../assets/offerings/interior/bedroom/2.webp"),
        require("../assets/offerings/interior/bedroom/3.webp"),
        require("../assets/offerings/interior/bedroom/4.webp"),
        require("../assets/offerings/interior/bedroom/5.webp"),
        require("../assets/offerings/interior/bedroom/6.webp"),
        require("../assets/offerings/interior/bedroom/7.webp"),
        require("../assets/offerings/interior/bedroom/8.webp"),
        require("../assets/offerings/interior/bedroom/9.webp"),
        require("../assets/offerings/interior/bedroom/10.webp")
      ]
    },
    {
      title: 'Living Room',
      description: 'Our design entails areas invitingly decorated and exquisitely styled fit for any social gathering.',
      imgmain: graphics[0],
      images: [
        require("../assets/offerings/interior/living-room/1.webp"),
        require("../assets/offerings/interior/living-room/2.webp"),
        require("../assets/offerings/interior/living-room/3.webp"),
        require("../assets/offerings/interior/living-room/4.webp"),
        require("../assets/offerings/interior/living-room/5.webp"),
        require("../assets/offerings/interior/living-room/6.webp"),
        require("../assets/offerings/interior/living-room/7.webp"),
        require("../assets/offerings/interior/living-room/8.webp"),
        require("../assets/offerings/interior/living-room/9.webp"),
        require("../assets/offerings/interior/living-room/10.webp")
      ]
    },
    {
      title: 'Wardrobes',
      description: 'Our storage solutions are attractive yet practical.',
      imgmain: graphics[2],
      images: [
        require("../assets/offerings/interior/wardrobes/1.webp"),
        require("../assets/offerings/interior/wardrobes/2.webp"),
        require("../assets/offerings/interior/wardrobes/3.webp"),
        require("../assets/offerings/interior/wardrobes/4.webp"),
        require("../assets/offerings/interior/wardrobes/5.webp"),
        require("../assets/offerings/interior/wardrobes/6.webp"),
        require("../assets/offerings/interior/wardrobes/7.webp"),
        require("../assets/offerings/interior/wardrobes/8.webp"),
        require("../assets/offerings/interior/wardrobes/9.webp"),
        require("../assets/offerings/interior/wardrobes/10.webp")
      ]
    },
    {
      title: 'Modular Kitchen',
      description: 'For modern and stylish kitchens we come up with designs.',
      imgmain: graphics[3],
      images: [
        require("../assets/offerings/interior/modular-kitchen/1.webp"),
        require("../assets/offerings/interior/modular-kitchen/2.webp"),
        require("../assets/offerings/interior/modular-kitchen/3.webp"),
        require("../assets/offerings/interior/modular-kitchen/4.webp"),
        require("../assets/offerings/interior/modular-kitchen/5.webp"),
        require("../assets/offerings/interior/modular-kitchen/6.webp"),
        require("../assets/offerings/interior/modular-kitchen/7.webp"),
        require("../assets/offerings/interior/modular-kitchen/8.webp"),
        require("../assets/offerings/interior/modular-kitchen/9.webp"),
        require("../assets/offerings/interior/modular-kitchen/10.webp")
      ]
    },
    {
      title: 'Bathroom',
      description: ' We create spaces that are elegant as well as functional where one can unwind.',
      imgmain: graphics[4],
      images: [
        require("../assets/offerings/interior/bathroom/1.webp"),
        require("../assets/offerings/interior/bathroom/2.webp"),
        require("../assets/offerings/interior/bathroom/3.webp"),
        require("../assets/offerings/interior/bathroom/4.webp"),
        require("../assets/offerings/interior/bathroom/5.webp"),
        require("../assets/offerings/interior/bathroom/6.webp"),
        require("../assets/offerings/interior/bathroom/7.webp"),
        require("../assets/offerings/interior/bathroom/8.webp"),
        require("../assets/offerings/interior/bathroom/9.webp"),
        require("../assets/offerings/interior/bathroom/10.webp").webpault
      ]
    },
    {
      title: 'Home Office',
      description: ' Personalised working areas created by us help in enhancing creativity as well as concentration.',
      imgmain: graphics[6],
      images: [
        require("../assets/offerings/interior/home-office/1.webp"),
        require("../assets/offerings/interior/home-office/2.webp"),
        require("../assets/offerings/interior/home-office/3.webp"),
        require("../assets/offerings/interior/home-office/4.webp"),
        require("../assets/offerings/interior/home-office/5.webp"),
        require("../assets/offerings/interior/home-office/6.webp"),
        require("../assets/offerings/interior/home-office/7.webp"),
        require("../assets/offerings/interior/home-office/8.webp"),
        require("../assets/offerings/interior/home-office/9.webp"),
        require("../assets/offerings/interior/home-office/10.webp")
      ]
    },
  ]