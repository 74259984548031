import React from "react"
import TopSection from "../UI/TopSection";
import { planIcons, planImages } from "../../constants/plan-approval";

const PlanTopSec = () =>{
    const structureData = {
        title: "Plan Approval Services",
        subtitle: "Fast & Hassle-Free Approvals",
        description: "At DesignElementary, we ensure fast,hassle-free, and compliant plan approvals for your projects.",
        stats: [
          { icon: planIcons.Municipal, label: "Municipal Clearances", count: 250 },
          { icon: planIcons.Approved, label: "Approved Projects", count: 300 },
          { icon: planIcons.Compliance, label: "Compliance Checks", count: 900 }
        ]
      };
  return (
    <><TopSection title={structureData.title} subtitle={structureData.subtitle} description={structureData.description} stats={structureData.stats} images={planImages}/></>
  );
}
export default PlanTopSec