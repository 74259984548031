import React from "react";
import CustomerSays from "../UI/CustomerSays";

const CustomerSaysOfWalk = () => {
    const testimonials = [
        { name: "Varun Desai", quote: "The walkthrough felt so real! Helped me finalize my dream home design." },
        { name: "Megha Choudhury", quote: "Absolutely stunning visuals! A must-have before construction." },
        { name: "Ramesh Patil", quote: "Helped me visualize my project with incredible detail. Great work!" },
        { name: "Kavita Shetty", quote: "Super realistic walkthroughs! Made my design decisions easy." },
        { name: "Harish Goel", quote: "Loved the immersive experience. It exceeded my expectations!" }
    ];

    return <CustomerSays testimonials={testimonials} />;
};

export default CustomerSaysOfWalk;
