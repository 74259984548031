import React from "react"

import Header from "../../components/Header"
import TopSecElevation from "../../components/3D/TopSection3D"
import SampleDesigns from "../../components/3D/sampleDesign"
import OurExpElevation from "../../components/3D/OurExp3D"
import HowItWorksElevation from "../../components/3D/HIWfor3d"
import WhyChooseUsElevation from "../../components/3D/whychsus3D"
import CustomerSaysOfElevation from "../../components/3D/custSays3d"
import ElevationFaq from "../../components/3D/FAQ3d"


const ElevationPage = () => {
    return (
        <>
            <Header />
            <TopSecElevation />
            <SampleDesigns />
            <OurExpElevation />
            <HowItWorksElevation />
            <WhyChooseUsElevation />
            <CustomerSaysOfElevation/>
            <ElevationFaq/>
        </>
    )
}
export default ElevationPage