import React from 'react';
import { OurExp as ExpertiseImages } from '../../constants/Elevation';
import OurExp from '../UI/OurExp';

const OurExpElevation = () => {
    const expertiseData = [
        {
            title: "Residential Elevations",
            image: ExpertiseImages[4]
        },
        {
            title: "Commercial & High-Rise Buildings",
            image: ExpertiseImages[0]
        },
        {
            title: "Custom Exterior Designs",
            image: ExpertiseImages[1]
        },
        {
            title: "Industrial & Institutional Structures",
            image: ExpertiseImages[2]
        },
        {
            title: "Night & Day Views",
            image: ExpertiseImages[3]
        },
        
    ];

    return (
        <OurExp expertiseData={expertiseData}/>
    );
};

export default OurExpElevation;
