import React from "react";
import { motion } from "framer-motion";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

const WhyChooseUs = ({ features }) => {
  const scrollRef = useInfiniteScroll();

  return (
    <div className="w-full py-16 bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <div className="max-w-[20rem] md:max-w-[25rem] mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            <div className="box a green-variant mb-12 p-3">
              <h1 className="text-2xl md:text-4xl text-center font-semibold text-gray-900">
                Why Choose Us ?
              </h1>
            </div>
          </h2>
        </div>

        {/* Scrolling Section */}
        <div className="relative w-full overflow-hidden">
          {/* Shadow Overlays */}
          <div className="absolute left-0 top-0 w-24 h-full bg-gradient-to-r from-gray-50 to-transparent z-10"></div>
          <div className="absolute right-0 top-0 w-24 h-full bg-gradient-to-l from-gray-50 to-transparent z-10"></div>

          {/* Infinite Scroll Container */}
          <div ref={scrollRef} className="flex overflow-hidden">
            <div className="flex animate-seamless-scroll">
              {/* First Set */}

              <div className="flex-none flex gap-8">
                {Array.from({ length: features.length * 2 }).map((_, i) => {
                  const index = i % features.length; // Loop through the elements
                  return (
                    <FeatureCard key={`set1-${i}`} feature={features[index]} />
                  );
                })}
              </div>

              {/* Duplicate Set for Seamless Scrolling */}
              <div className="flex-none flex gap-8 ml-8">
                {Array.from({ length: features.length * 2 }).map((_, i) => {
                  const index = i % features.length; // Loop through the elements
                  return (
                    <FeatureCard key={`set2-${i}`} feature={features[index]} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ feature }) => {
  return (
    <motion.div
      className="relative w-64 h-64 cursor-pointer"
      initial={{ rotateY: 0 }}
      whileHover={{ rotateY: 180 }}
      transition={{ duration: 0.6 }}
      style={{ transformStyle: "preserve-3d", perspective: "1000px" }}
    >
      {/* Front Side */}
      <div className="absolute w-full h-full bg-white p-6 rounded-xl shadow-md flex flex-col items-center justify-center text-center ">
        <div className="w-20 h-20 mb-4">
          <img
            src={feature.icon}
            alt={feature.title}
            className="w-full h-full object-contain"
          />
        </div>
        <h3 className="text-[#0e906f] font-semibold text-lg">
          {feature.title}
        </h3>
      </div>

      {/* Back Side */}
      <div
        className="absolute w-full h-full bg-[#0e906f] text-white p-6 rounded-xl shadow-md flex items-center justify-center text-center"
        style={{ transform: "rotateY(180deg)", backfaceVisibility: "hidden" }}
      >
        <p className="text-sm">{feature.description}</p>
      </div>
    </motion.div>
  );
};

export default WhyChooseUs;
