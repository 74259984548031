import React, { useState } from 'react';
import FaqSection from '../UI/FAQsection';

const PlanFaq = () => {
  // const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What is the process for getting a plan approval?",
      answer: "Our process includes design validation, documentation, submission, and obtaining approval from authorities."
    },
    {
      question: "How long does it take to get approval?",
      answer: "Timelines vary based on local regulations, but we streamline the process for faster approvals."
    },
    {
      question: "What documents are required for plan approval?",
      answer: "Typically, site plans, structural designs, and environmental clearances are needed."
    },
    {
      question: "Do you assist with legal compliance for approvals?",
      answer: "Yes, we ensure that your project complies with all legal and regulatory requirements."
    },
    {
      question: "Can you help with re-approvals or modifications?",
      answer: "Yes, we handle modifications and re-approvals if changes are needed."
    }
  ];

  return <FaqSection faqData={faqs} />;
};

export default PlanFaq;
