export const ElevationImages = [
    require('../assets/3D/banner_image/1.jpg'),
    require('../assets/3D/banner_image/2.jpg'),
    require('../assets/3D/banner_image/3.jpg')
];
export const ElevationIcons = {
    Municipal: require('../assets/3D/banner_icon/500_Luxury_Exteriors.png'),
    Approved: require('../assets/3D/banner_icon/900_Custom_Elevations.png'),
    Compliance: require('../assets/3D/banner_icon/1500_Rendered_Designs.png')
};

export const OurExp = [
    require('../assets/3D/Our_expertise/Commercial_and_High_Rise_Buildings.png'),
    require('../assets/3D/Our_expertise/Custom_Exterior_Designs.png'),
    require('../assets/3D/Our_expertise/Industrial_and_Institutional_Structures.png'),
    require('../assets/3D/Our_expertise/Night_and_Day_Views.png'),
    require('../assets/3D/Our_expertise/Residential_Elevations.png')
];

export const ElevationSampleImages = [
    require('../assets/3D/sample_image/1.jpg'),
    require('../assets/3D/sample_image/2.jpg'),
    require('../assets/3D/sample_image/3.jpg')
];



