import React from "react";
import CustomerSays from "../UI/CustomerSays";

const CustomerSaysOfStructure = () => {
    const testimonials = [
        { name: "Amit Sharma", quote: "Excellent structural planning, ensuring safety and durability!" },
        { name: "Pooja Reddy", quote: "Their designs are innovative yet practical. Highly recommended!" },
        { name: "Rajesh Verma", quote: "Strong, efficient, and cost-effective structural solutions." },
        { name: "Sneha Iyer", quote: "They delivered on time with top-quality engineering expertise." },
        { name: "Vikas Nair", quote: "Great attention to detail and adherence to safety standards!" }
      ];

  return (
    
    <>
    <CustomerSays testimonials={testimonials}/>
    </>
    )
};

export default CustomerSaysOfStructure;
