import React, { useState } from 'react';
import FaqSection from '../UI/FAQsection';

const MEPFaq = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What does MEP design include?",
      answer: "MEP design covers HVAC, electrical systems, water supply, drainage, and fire protection."
    },
    {
      question: "Do you provide energy-efficient solutions?",
      answer: "Yes, we focus on sustainable and energy-efficient MEP designs."
    },
    {
      question: "Can MEP designs be customized for my project?",
      answer: "Absolutely! We tailor designs to suit your specific building requirements."
    },
    {
      question: "Do you handle MEP installations along with designs?",
      answer: "Yes, we provide complete MEP solutions, including design, planning, and implementation."
    },
    {
      question: "How do you ensure compliance with regulations?",
      answer: "We strictly adhere to local and international MEP standards for safety and efficiency."
    }
  ];

  

  return (
    <FaqSection faqData={faqs}/>
  );
};

export default MEPFaq;
