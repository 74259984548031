import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { IoIosArrowDown, IoIosMenu, IoIosClose, IoIosArrowForward } from "react-icons/io";
import Dropdown from "./Dropdown";
import { RxAvatar } from "react-icons/rx";
import { AiOutlineShoppingCart, AiOutlineProject, AiOutlineDollarCircle, AiOutlineCalculator } from "react-icons/ai";
import { BiHomeAlt,  BiCalculator, BiImage} from "react-icons/bi"; // Add this import
import { FaArrowRight } from "react-icons/fa";
import { analytics, logEvent } from "../firebase/firebase";

const shimmerAnimation = `
@keyframes shimmer {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}`;

export default function Header() {
  const { user } = React.useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [offeringsOpen, setOfferingsOpen] = useState(false); // new state for offerings collapse
  const [architectOpen, setArchitectOpen] = useState(false);
  const [designIdeasOpen, setDesignIdeasOpen] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const location = useLocation();
  // const isHome = location.pathname === "/";

  const navLinks = {
    designAi: "/user/profile/design-ai",
    designIdeas: "/blogs",
    shopMaterials: "https://b2c.designelementary.in/#/raw-materials",
    pmc: "/site-supervision-and-quality-audit",
    finance: "https://basichomeloan.designelementary.in/",
    costCalculator: "/cost-calculator",
    cities: '/cities',
  };

  const offerings = [
    {
      name: "Interior",
      link: "/interior"
    },
    {
      name: "Turnkey Construction",
      link: "/turnkey-construction"
    },
    {
      name: "Site Supervision & Quality Audit",
      link: "/site-supervision-and-quality-audit"
    }
  ];

  const architect = [
    {
      name: ' Structure',
      link: '/structure'
    },
    {
      name: "Layout",
      link: "/design/floorplan"
    },
    {
      name: "MEP",
      link: "/mep"
    },
    {
      name: "Walkthrough",
      link: "/walk-through"
    },
    {
      name: "Plan Approval",
      link: "/plan-approval"
    },
  ]

  const designIdeas = [

    {
      name: "Exterior",
      link: "/design/exterior"
    },
    {
      name: "Landscape",
      link: "/design/landscape"
    },
    {
      name: "3D Elevation",
      link: "/3D-elevation"
    },
  ]

  React.useEffect(() => { }, [user]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleOfferings = () => {
    setOfferingsOpen(!offeringsOpen);
  };

  const toggleArchitect = () => {
    setArchitectOpen(!architectOpen);
  };

  const toggleDesignIdeas = () => {
    setDesignIdeasOpen(!designIdeasOpen);
  };

  // Add scroll event listener
  React.useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrollingDown(currentScrollY > lastScrollY);
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <style>{shimmerAnimation}</style>
      <div className="h-6 w-full md:hidden bg-green-800 text-white flex items-center justify-center font-semibold">

        <Link to={'https://plan.designelementary.in/'}>
          <span className="mx-auto py-1.5 gap-3 flex justify-center items-center"> Offer 30% Off !
            <FaArrowRight color="white" />
            {/* <motion.div
              className="absolute top-0 right-0 h-full w-16 opacity-70"
              style={{
                clipPath: 'polygon(100% 100%, 0% 0, 100% 0)',
                background: 'linear-gradient(75deg, transparent 0%, rgba(255, 215, 0, 0.9) 50%, transparent 100%)',
                boxShadow: '0 0 15px rgba(255, 215, 0, 0.7), 0 0 25px rgba(255, 215, 0, 0.5)'
              }}
              animate={{
                x: [-200, 200],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "reverse"
              }}
            /> */}
          </span>
        </Link>


      </div>
      <div className="md:w-full md:shadow-md mx-auto md:fixed  w-full z-[500000000] group">
        <div className="flex px-8 item justify-between md:px-16 w-full md:shadow-md relative bg-white z-[100] items-center py-3 transition-all duration-300">
          <Link to="/" className="">
            <img
              src={require("../assets/navlogo.svg").default}
              alt="Design Elementary"
              className="md:w-[220px] w-[40vw]"
            />
          </Link>


          {/* Mobile Offer Button */}
          <div className="md:hidden">

          </div>
          {(!user.Id && window.location.pathname !== "/login") && (
            <Link to="/login" onClick={toggleMenu}>
              <button
                className="inline-flex items-center gap-2 font-semibold text-base px-3 py-2.5 rounded-3xl
                bg-[#1B454D] text-white shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                  transition-all duration-200 md:hidden ease-in-out focus:outline-none focus:ring-2">

                Login
              </button>
            </Link>
          )}
          {user.Id && (
            <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
              <div className="w-full px-4 py-2 border-b md:hidden border-gray-200 hover:bg-gray-100 flex items-center">
                <RxAvatar size={32} className="rounded-full" color="#31500c" />
              </div>
            </Link>
          )}

          {/* Desktop Menu */}
          <div className="hidden ml-5 md:block tooltip px-2 py-2 bg-white text-black tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">
            <div className="flex gap-8 text-xl md:text-base">
              <Dropdown label="Architect" items={architect} width="200px" />
              <Dropdown label="Design Ideas" items={designIdeas} width="200px" />
              <Dropdown label="Offerings" items={offerings} width="200px" />
              <Link to={navLinks.cities}>
                <button className="cool-link gap-2 text-[#1f2c0f] text-[1.1rem] hover:text-gray-700 transition font-semibold px-4 py-2.5">
                  Cities
                </button>
              </Link>
            </div>
          </div>

          {/* Login, DesignAI and Avatar Links */}
          <div className="hidden md:flex items-center gap-4 tooltip px-2 py-2 bg-white text-green-400 tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">

            <Link
              to={'https://plan.designelementary.in/'}
              target="_blank"
              rel="noopener noreferrer"
              className="relative overflow-hidden inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5
              border-[#1B454D] rounded-3xl border-r-8
              text-[#1B454D] border shadow-md hover:shadow-lg transform hover:-translate-y-0.5
              transition-all duration-200 ease-in-out focus:outline-none focus:ring-2
              before:absolute before:top-0 before:left-0 before:w-full before:h-full
              before:bg-gradient-to-r before:from-transparent before:via-white/50 before:to-transparent
              before:animate-[shimmer_1.5s_ease-in-out_infinite]"
            >
              <span>Limited Offer 30% Off !</span>
            </Link>

            {!user.Id && (
              <Link
                to="/login" >
                <button
                  className="inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5 rounded-3xl
               bg-[#1B454D] text-white shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                 transition-all duration-200 ease-in-out focus:outline-none focus:ring-2">
                  Login
                </button>
              </Link>
            )}
            {user.Id && (
              <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer">
                <div className="relative group cursor-pointer">
                  <RxAvatar
                    size={32}
                    className="rounded-full transition-transform duration-300 ease-in-out transform group-hover:scale-110 group-hover:shadow-lg group-hover:ring-2 group-hover:ring-gray-300"
                  />

                </div>
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Side Menu */}
        <div
          className={`fixed top-0 left-0 h-full w-72 bg-white shadow-lg z-[100000003] 
        transform transition-all duration-300 ease-in-out
        ${menuOpen ? 'translate-x-0' : 'translate-x-[-100%]'}`}
        >
          <div className="h-full  overflow-y-auto">
            <div className="sticky top-0 bg-white z-10 p-4 border-b">
              <button onClick={toggleMenu} className="mb-4">
                <IoIosClose size={30} />
              </button>
            </div>
            <div className="p-4 flex flex-col gap-2">

              {!user.Id && (
                <Link to="/login" onClick={() => {
                  toggleMenu();
                  logEvent(analytics, 'login', {
                    title: "Login Clicked",
                  });
                }
                }>
                  <button
                    className="inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5 rounded-3xl
                                  bg-[#1B454D] text-white shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                                    transition-all duration-200 ease-in-out focus:outline-none focus:ring-2"
                  >
                    Login
                  </button>
                </Link>
              )}
              {user.Id && (
                <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                  <div className="w-full px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex items-center">
                    <RxAvatar size={32} className="rounded-full" color="#31500c" />
                  </div>
                </Link>
              )}
              <button onClick={toggleArchitect} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Architect</span>
                {architectOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {architectOpen && (
                <div className="flex flex-col">
                  {architect.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <button onClick={toggleDesignIdeas} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Design Ideas</span>
                {designIdeasOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {designIdeasOpen && (
                <div className="flex flex-col">
                  {designIdeas.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <button onClick={toggleOfferings} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Offerings</span>
                {offeringsOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {offeringsOpen && (
                <div className="flex flex-col">
                  {offerings.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <Link to={navLinks.cities} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                  Cities
                </button>
              </Link>
              <Link to={navLinks.designAi} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex justify-between items-center">
                  <span className="flex items-center gap-1">
                    <span>
                      <img className=" animate-spin w-6 transition-all" src={require('../assets/sparkling.png')} alt="" />
                    </span>
                    Design AI
                    <span className="animate-pulse inline-block"></span>
                  </span>  </button>
              </Link>
              <Link to={navLinks.shopMaterials} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineShoppingCart size={20} />
                  <span>Shop Materials</span>
                </button>
              </Link>
              <Link to={navLinks.pmc} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineProject size={20} />
                  <span>PMC</span>
                </button>
              </Link>
              <Link to={navLinks.finance} onClick={() => { toggleMenu(); logEvent(analytics, 'subheader_finance'); }}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineDollarCircle size={20} />
                  <span>Finance</span>
                </button>
              </Link>
              <Link to={navLinks.costCalculator} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineCalculator size={20} />
                  <span>Cost Calculator</span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* Add overlay when menu is open */}
        {menuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-[100000002] transition-opacity duration-300"
            onClick={toggleMenu}
          />
        )}

        {/* SVG Menu Section - Modified for hover */}
        <div className={`absolute h-16 w-[46%] top-[80px] shadow-lg  hidden` + (isScrollingDown ? 'md:hidden opacity-0' : 'md:block')} style={{
          left: "50%",
          transform: "translateX(-50%)",
        }} />
        <div
          className={`absolute bg-transparent z-50 h-16 w-[62%] top-[84px] md:flex items-center justify-center 
            group-hover:opacity-100 group-hover:translate-y-0  transition-all duration-300 hidden ` + (isScrollingDown ? 'md:hidden opacity-0' : 'md:block')}
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='600' height='80' viewBox='0 0 750 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M751.738 1.27464e-05C803.425 1.20807e-05 -48.4647 -1.43032e-05 6.55652 1.0839e-05C29.2818 2.12234e-05 45.0566 8.5296 55.7458 18.5429C72.0398 33.8065 91.25 50 113.576 50H349.489H647.199C668.568 50 687.144 35.3519 702.317 20.3044C713.159 9.55104 729.148 1.30373e-05 751.738 1.27464e-05Z' fill='white'/%3E%3C/svg%3E")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div className="flex items-center justify-center gap-8 mx-6 text-sm relative">
            <Link to={navLinks.designAi}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <span className="flex items-center gap-1">
                    <span>
                      <img className=" animate-spin  w-6 transition-all" src={require('../assets/sparkling.png')} alt="" />
                    </span>
                    Design AI
                    <span className="animate-pulse inline-block"></span>
                  </span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.shopMaterials}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineShoppingCart size={20} />
                  <span>Shop Materials</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.pmc}>

              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineProject size={20} />
                  <span>PMC</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.finance}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineDollarCircle size={20} />
                  <span>Finance</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.costCalculator}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineCalculator size={20} />
                  <span>Cost Calculator</span>
                </span>
              </button>
            </Link>
          </div>
        </div>


      </div>

      {/* Bottom Navigation Bar for Mobile */}
      <div className="fixed bottom-0 w-full left-0 bg-white border-t border-gray-200 md:hidden z-[500000000]">
        <div className="flex py-2 justify-around items-center h-16 relative">
          <Link to="/" className="flex flex-col items-center py-2">
            <BiHomeAlt size={24} className="text-gray-600" />
            <span className="text-xs font-semibold text-gray-600">Home</span>
          </Link>
          <Link to="/cost-calculator" className="flex flex-col items-center py-2 mr-[18px]">
            <BiCalculator size={24} className="text-gray-600" />
            <span className="text-xs font-semibold text-gray-600">Calculate</span>
          </Link>
          {/* Center Logo Button */}
          <div className="flex flex-col items-center  p-4 relative">
            <button className="absolute -top-12 shadow bg-white rounded-full h-16 w-16 transform -translate-x-1/5">
              <Link to='https://b2c.designelementary.in/#/projects'>
                <img src="/logo.png" alt="" className="h-16 w-16 rounded-full" />
              </Link>
              <p className="text-xs mt-1 font-semibold w-28 -ml-[10px] text-start text-gray-600">Create Project</p>
            </button>
          </div>
          <Link to='/blogs' className="flex flex-col items-center py-2 ml-[18px]">
            <BiImage size={24} className="text-gray-600" />
            <span className="text-xs font-semibold text-gray-600">DE Ideas</span>
          </Link>
          <button className="flex flex-col items-center py-2" onClick={toggleMenu}>
            <IoIosMenu size={24} className="text-gray-600" />
            <span className="text-xs font-semibold text-gray-600">Menu</span>
          </button>
        </div>
      </div>
    </>
  );
}