import React, { useState } from 'react';
import HowItWorks from '../UI/HowItsWorks';

const HowItWorksStructure = () => {
    

    const steps = [
        {
            number: 1,
            title: "Consultation & Project Brief ",
            icon: require('../../assets/Structure/HOW_IT_WORKS_icon/Consultation_and_Project_Brief.png'),
            description: "Initial discussion about your project requirements and scope"
        },
        {
            number: 2,
            title: "3D Modeling & Design",
            icon: require('../../assets/Structure/HOW_IT_WORKS_icon/3D_Modeling_and_Design.png'),
            description: "Detailed cost estimation and portfolio presentation"
        },
        {
            number: 3,
            title: "Rendering & Animation",
            icon: require('../../assets/Structure/HOW_IT_WORKS_icon/Rendering_and_Animation.png'),
            
            description: "Schedule your project timeline"
        },
        {
            number: 4,
            title: "Client Review & Modification",
            icon: require('../../assets/Structure/HOW_IT_WORKS_icon/Client_Review_and_Modifications.png'),
            
            description: "Comprehensive MEP system design and documentation"
        },
        {
            number: 5,
            title: "Final Delivery",
            icon: require('../../assets/Structure/HOW_IT_WORKS_icon/Final_Delivery.png'),
            description: "Quality check and project delivery"
        }
    ];


    return (
        <><HowItWorks steps={steps}/></>
    );
};

export default HowItWorksStructure;
