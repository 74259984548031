import React from "react"
import { WalkIcons,WalkVids} from "../../constants/walkthrough";
import TopSecVids from "../UI/TopSecTakeVids";

const WalkTopSec = () =>{
    const WalkData = {
        title: "Architectural Walkthrough –",
        subtitle: "Bring Your Vision to Life",
        description: "At DesignElementary, we create immersive, realistic architectural walk-throughs for a complete project vision.",
        stats: [
          { icon: WalkIcons.Approved, label: "3D Visuals", count: 1200 },
          { icon: WalkIcons.Commercial, label: "Commercial Walks", count: 300 },
          { icon: WalkIcons.Residential, label: "Residential Tours", count: 650 }
        ]
      };
  return (
    <><TopSecVids title={WalkData.title} subtitle={WalkData.subtitle} description={WalkData.description} stats={WalkData.stats} videos={WalkVids}/></>
  );
}
export default WalkTopSec