import React from 'react';
import { OurExp as ExpertiseImages } from '../../constants/Structure';
import OurExp from '../UI/OurExp';

const OurExpStructure = () => {
    const expertiseData = [
        {
            title: "Commercial & Residential Structures",
            image: ExpertiseImages[0]
        },
        {
            title: "Industrial & Infrastructure Projects",
            image: ExpertiseImages[1]
        },
        {
            title: "Structural Analysis & Retrofitting",
            image: ExpertiseImages[3]
        },
        {
            title: "Seismic & Load Calculations",
            image: ExpertiseImages[2]
        }
    ];

    return (
        <OurExp expertiseData={expertiseData}/>
    );
};

export default OurExpStructure;
