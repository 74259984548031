import React, { useState } from "react";

const CustomerSays = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const goToNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + 2 >= testimonials.length ? 0 : prevIndex + 2
    );
  };
  
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex - 2 < 0 ? Math.max(testimonials.length - 2, 0) : prevIndex - 2
    );
  };
  
  return (
    <div className="w-full py-16 bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative">
  <div className="mb-12">
    <h2 className="text-4xl font-bold text-[#002B3D]">
      What our<br />customers are saying
    </h2>
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 relative">
    {testimonials.length > 0 && (
      <div className="bg-[#002B3D] p-8 rounded-xl text-white relative group hover:bg-[#003a52] transition-colors duration-300">
        {/* Quote Icon */}
        <div className="absolute -top-4 left-8 text-[#0e906f] text-6xl opacity-30">
          "
        </div>
        <div className="relative">
          <p className="text-lg mb-6 leading-relaxed">{testimonials[currentIndex].quote}</p>
          <div className="flex items-center mt-4">
            {testimonials[currentIndex].image && (
              <div className="mr-4">
                <img 
                  src={testimonials[currentIndex].image} 
                  alt={testimonials[currentIndex].name}
                  className="w-16 h-16 rounded-full object-cover" 
                />
              </div>
            )}
            <h3 className="font-semibold text-yellow-500">{testimonials[currentIndex].name}</h3>
          </div>
        </div>
      </div>
    )}

    {testimonials.length > 1 && (
      <div className="bg-[#002B3D] p-8 rounded-xl text-white relative group hover:bg-[#003a52] transition-colors duration-300">
        {/* Quote Icon */}
        <div className="absolute -top-4 left-8 text-[#0e906f] text-6xl opacity-30">
          "
        </div>
        <div className="relative">
          <p className="text-lg mb-6 leading-relaxed">{testimonials[currentIndex + 1 < testimonials.length ? currentIndex + 1 : 0].quote}</p>
          <div className="flex items-center mt-4">
            {testimonials[currentIndex + 1 < testimonials.length ? currentIndex + 1 : 0].image && (
              <div className="mr-4">
                <img 
                  src={testimonials[currentIndex + 1 < testimonials.length ? currentIndex + 1 : 0].image} 
                  alt={testimonials[currentIndex + 1 < testimonials.length ? currentIndex + 1 : 0].name}
                  className="w-16 h-16 rounded-full object-cover" 
                />
              </div>
            )}
            <h3 className="font-semibold text-yellow-500">{testimonials[currentIndex + 1 < testimonials.length ? currentIndex + 1 : 0].name}</h3>
          </div>
        </div>
      </div>
    )}
  </div>

  {/* Navigation Buttons Positioned on Left and Right */}
  <button 
    onClick={goToPrevious}
    className="absolute -left-10 top-3/4 transform -translate-y-1/2 w-12 h-12 rounded-full bg-[#002B3D] text-white flex items-center justify-center hover:bg-[#003a52] transition-colors z-10"
    aria-label="Previous testimonials"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  </button>

  <button 
    onClick={goToNext}
    className="absolute -right-10 top-3/4 transform -translate-y-1/2 w-12 h-12 rounded-full bg-[#002B3D] text-white flex items-center justify-center hover:bg-[#003a52] transition-colors z-10"
    aria-label="Next testimonials"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  </button>
</div>

    </div>
  );
};

export default CustomerSays;