import React from "react";
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';  // Add this import

export const ProjectLocationsforPlan = () => {
  const scrollRef = useInfiniteScroll();  // Add this hook
  const projects = [
    { city: "Bangalore", image: "bangalore.png" },
    { city: "Hyderabad", image: "hyderabad.png" },
    { city: "Kolkata", image: "mumbai.png" },
    { city: "Ranchi", image: "pune.png" },
    { city: "Patna", image: "ahmedabad.png" },
    { city: "Chennai", image: "chennai.png" },

  ];

  return (
    <div className="w-full bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center gap-8">
          {/* Header Section */}
          <div className="text-center max-w-4xl ">

            <div className='flex items-center justify-center'>
              <h2 className="md:text-4xl text-2xl mb-4 font-bold text-center">
                Delivering Plan Approvals Across These Cites
              </h2>
            </div>
            <p className="text-lg text-gray-600 mb-8">
              Explore our unique home designs from India</p>
          </div>

          {/* Updated Sliding Section */}
          <div className="relative w-full overflow-hidden">
            {/* Shadow overlays */}
            <div className="absolute left-0 top-0 w-32 h-full bg-gradient-to-r from-gray-50 to-transparent z-10"></div>
            <div className="absolute right-0 top-0 w-32 h-full bg-gradient-to-l from-gray-50 to-transparent z-10"></div>

            {/* Infinite scroll container */}
            <div ref={scrollRef} className="flex overflow-hidden">
              <div className="flex animate-seamless-scroll">
                {/* First set */}
                <div className="flex-none flex">
                  {projects.map((project, index) => (
                    <div key={`set1-${index}`} className="flex-shrink-0 w-[280px] px-4">
                      <ProjectCard city={project.city} image={project.image} />
                    </div>
                  ))}
                </div>
                {/* Duplicate set for seamless scrolling */}
                <div className="flex-none flex">
                  {projects.map((project, index) => (
                    <div key={`set2-${index}`} className="flex-shrink-0 w-[280px] px-4">
                      <ProjectCard city={project.city} image={project.image} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProjectCard({ city, image }) {
  return (
    <div className="group hover:scale-105 transition-all duration-300">
      <div className="bg-white rounded-xl shadow-sm hover:shadow-md p-6 flex flex-col items-center justify-center gap-4 transition-all duration-300">
        <div className="bg-green-50 p-5 rounded-full">
          <img
            className="h-16 w-16 object-contain "
            src={require(`../../assets/locations/${image}`)}
            alt={`${city} projects`}
          />
        </div>

        <div className="text-center">
          <p className="text-gray-500 text-sm mb-1">Projects In</p>
          <h3 className="text-gray-800 font-semibold text-lg">{city}</h3>
        </div>
      </div>
    </div>
  );
}
