import React, { useState } from "react";

const HowItWorks = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prev) => (prev === steps.length - 1 ? 0 : prev + 1));
  };

  const prevStep = () => {
    setCurrentStep((prev) => (prev === 0 ? steps.length - 1 : prev - 1));
  };

  return (
    <div className="w-full py-4 md:py-16 bg-white">
      <div className="max-w-7xl mx-auto px-2 md:px-4">
        <div className="max-w-[16rem] md:max-w-[22rem] mx-auto">
          <h2 className="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-12">
            <div className="box a green-variant p-2 md:p-3">
              <h1 className="text-2xl  md:text-4xl text-center font-semibold text-gray-900">
                How It Works
              </h1>
            </div>
          </h2>
        </div>

        {/* Timeline Steps */}
        <div className="relative mb-6 md:mb-20">
          {/* Timeline Line */}
          <div className="absolute top-[14px] md:top-[22px] left-[10%] right-[10%] h-0.5 bg-[#0e906f]" />

          {/* Step Dots */}
          <div className="flex justify-between items-start">
            {steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center w-1/5 cursor-pointer"
                onClick={() => setCurrentStep(index)} // Update the current step on click
              >
                <div
                  className={`w-7 md:w-12 h-7 md:h-12 rounded-full ${
                    index === currentStep ? "bg-[#002B3D]" : "bg-[#4e9a8e]"
                  } text-${
                    index === currentStep ? "yellow-400" : "white"
                  } flex items-center justify-center text-xs md:text-xl font-bold mb-1 md:mb-4 relative z-20`}
                >
                  {step.number}
                </div>
                <p
                  className={`text-[9px] md:text-sm ${
                    index === currentStep
                      ? "text-[#0e906f] font-semibold"
                      : "text-gray-600"
                  } max-w-[60px] md:max-w-[120px] pt-1`}
                >
                  {step.title}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Current Step Details */}
        <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-8">
          {/* Desktop Navigation */}
          <button
            onClick={prevStep}
            className="hidden md:flex w-6 h-6 md:w-10 md:h-10 rounded-full bg-[#002B3D] font-semibold text-yellow-400 items-center justify-center hover:bg-[#0e906f] transition-colors"
          >
            ←
          </button>

          <div className="flex flex-col md:flex-row items-center gap-3 md:gap-8 w-full md:w-[600px]">
            <div className="w-32 h-32 md:w-48 md:h-48 p-2 md:p-8 bg-white rounded-lg shadow-lg">
              <img
                src={steps[currentStep].icon}
                alt={steps[currentStep].title}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="flex-1 text-center md:text-left">
              <h3 className="text-base md:text-2xl font-bold text-[#002B3D] mb-1 md:mb-4">
                {currentStep + 1}. {steps[currentStep].title}
              </h3>
              <p className="text-xs md:text-base text-gray-600">
                {steps[currentStep].description}
              </p>
            </div>
          </div>

          <button
            onClick={nextStep}
            className="hidden md:flex w-6 h-6 md:w-10 md:h-10 rounded-full bg-[#002B3D] font-semibold text-yellow-400 items-center justify-center hover:bg-[#0e906f] transition-colors"
          >
            →
          </button>
        </div>

        {/* Mobile Navigation (Buttons at Bottom) */}
        <div className="flex md:hidden gap-10 justify-center mt-6">
          <button
            onClick={prevStep}
            className="w-8 h-8 rounded-full bg-[#002B3D] font-semibold text-yellow-400 flex items-center justify-center hover:bg-[#0e906f] transition-colors"
          >
            ←
          </button>
          <button
            onClick={nextStep}
            className="w-8 h-8 rounded-full bg-[#002B3D] font-semibold text-yellow-400 flex items-center justify-center hover:bg-[#0e906f] transition-colors"
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
