export const mepImages = [
    require('../assets/MEP/MEP_Header_Image/1.jpg'),
    require('../assets/MEP/MEP_Header_Image/2.jpg'),
    require('../assets/MEP/MEP_Header_Image/3.jpg')
];
export const mepIcons = {
    hvac: require('../assets/MEP/banner_icon/300_HVAC_Designs.png'),
    water: require('../assets/MEP/banner_icon/400_Water_Solutions.png'),
    building: require('../assets/MEP/banner_icon/550_Systems_Installed.png')
};

export const OurExp = [
    require('../assets/MEP/Our_expertise_image/Electrical_Layouts.png'),
    require('../assets/MEP/Our_expertise_image/Fire_Safety_and_Protection_Systems.png'),
    require('../assets/MEP/Our_expertise_image/HVAC_and_Ventilation_Systems.png'),
    require('../assets/MEP/Our_expertise_image/Plumbing_and_Drainage_Systems.png')
] 
export const sampleImage = [
    require('../assets/MEP/SAMPLE_IMAGE/1.jpg'),
    require('../assets/MEP/SAMPLE_IMAGE/2.jpg'),
    require('../assets/MEP/SAMPLE_IMAGE/3.jpg')
]

