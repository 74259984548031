import React from "react";
import WhyChooseUs from "../UI/WhyChooseUs";

const WhyChooseUsWalk = () => {
  const features = [
    {
      icon: require("../../assets/Walkthrough/why_choose_us/Marketing_and_Client_Presentations.png"),
      title: "Marketing & Client Presentations",
      description: "",
    },

    {
      icon: require("../../assets/Walkthrough/why_choose_us/Cost_Effective_Revisions.png"),
      title: "Cost-Effective Revisions",
      description: "",
    },

    {
      icon: require("../../assets/Walkthrough/why_choose_us/Interactive_Virtual_Tour.png"),
      title: "Interactive Virtual Tour",
      description: "",
    },
    {
      icon: require("../../assets/Walkthrough/why_choose_us/Detailed_Interiors_Exteriors.png"),
      title: "Detailed Interiors & Exteriors",
      description: "",
    },

    {
      icon: require("../../assets/Walkthrough/why_choose_us/Realistic_3D_Visualization.png"),
      title: "Realistic 3D Visualization",
      description: "",
    },
  ];

  return (
    <>
      <WhyChooseUs features={features} />
    </>
  );
};

export default WhyChooseUsWalk;
