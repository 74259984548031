import { useRef, useEffect, useState } from 'react';

export const useInfiniteScroll = (speed = 0.5) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (!scrollContainer) return;

        // Add hover handlers
        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        scrollContainer.addEventListener('mouseenter', handleMouseEnter);
        scrollContainer.addEventListener('mouseleave', handleMouseLeave);

        let lastTimestamp = 0;
        const animate = (timestamp: number) => {
            if (!lastTimestamp) lastTimestamp = timestamp;
            const delta = timestamp - lastTimestamp;

            if (!isHovered && scrollContainer) {
                scrollContainer.scrollLeft += speed * (delta / 16);

                // Reset scroll position smoothly when reaching the end
                const { scrollLeft, scrollWidth } = scrollContainer;
                const maxScroll = scrollWidth / 2;
                
                if (scrollLeft >= maxScroll) {
                    scrollContainer.scrollLeft = 0;
                }
            }

            lastTimestamp = timestamp;
            return requestAnimationFrame(animate);
        };

        const animation = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(animation);
            scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
            scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [speed, isHovered]);

    return scrollRef;
};
