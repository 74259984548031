import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ArchitectureBanner from '../../components/interior/BannerArchitecture';
import NumberAnimation from '../../components/NumberAnimation';
import ImagesCard from '../../components/interior/ImagesCard';
import { whatWeOffer } from '../../constants/Interior';
import CustomerReviews from '../../components/interior/Reviews';
import { ArchitectureFAQs, ArchitectureOfferings, ArchitectureProcesses } from '../../constants/ArchitectureData';

const Architecture = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white">

      <Header />

      {/*Top Section */}
      <div className="w-full flex pt-20 items-center justify-center relative mx-auto  text-black" >
        <video className="w-full max-h-screen object-cover md:h-auto h-[900px]" autoPlay loop muted playsInline>
          <source src={require("../../assets/offerings/architecture/video.webm")} type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className='w-full h-full absolute bg-black z-10 opacity-30'></div>
        <div className='text-white absolute flex flex-col my-2 justify-center items-center z-50'>
          <h1 className="font-black text-[2rem] md:text-[3rem] font-[Italiana] text-center">
            ARCHITECTURE DESIGN
          </h1>
          <p className='mb-10 text-xl md:text-3xl'>
            Crafting Beauty in Every Corner
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=+916363759171&text=Hello,%20I%20would%20like%20to%20know%20more%20about%20Your%Designs"
            className=' font-semibold text-green-900 rounded-md py-1.5 px-3 text-2xl bg-yellow-400 font-[Italiana]'>
            CONTACT US
          </a>
        </div>
      </div>

      {/* What we offer */}

      <div className='py-10 bg-[#01382A] text-white'>
        <h1 className="font-bold md:text-[45px] text-[28px] text-center mb-10  w-fit mx-auto">
          WHAT WE OFFER
        </h1>

        <div className='grid md:grid-cols-6 md:px-10 grid-cols-2 justify-items-center gap-8 '>
          {
            whatWeOffer.map((item, index) => {
              return (
                <div key={index} className='flex relative flex-col w-44 h-44 items-center'>
                  <img
                    src={item.image}
                    className='h-24 object-contain'
                    alt=""
                  />
                  <h1 className='text-lg  py-4 font-semibold text-center '>{item.text}</h1>
                </div>
              )
            })
          }
        </div>
      </div>

      {/* Our Offerings */}
      <div className='md:px-24 px-5 py-10'>
        <h1 className="font-bold md:text-[45px] text-[28px] text-center text-black mb-14  w-fit mx-auto">
          OUR AREA OF EXPERTISE IN DESIGN
        </h1>
        <div className='grid md:grid-cols-2 md:gap-16 gap-10 grid-cols-1'>
          {ArchitectureOfferings.map((item, index) =>
            <ImagesCard key={index} item={item} index={index} />
          )}
        </div>
      </div>

      <ArchitectureBanner />

      {/* Our Design Process */}

      <div className='md:px-24 px-5 bg-[#01382A] py-10'>
        <h1 className="font-bold md:text-[45px] text-[28px] text-center text-white mb-14 w-fit mx-auto">
          OUR DESIGN PROCESS
        </h1>

        {/* First row: grid layout with 3 items */}
        <div className='grid md:grid-cols-3 gap-12 grid-cols-1'>
          {ArchitectureProcesses.map((item, index) => (
            <div key={index}>
              <div className='flex justify-center items-center'>
                <div className='relative'>
                  <h1 className='absolute z-50 top-0 left-0  bg-green-700 rounded text-3xl text-yellow-400 border-dashed border-2  border-yellow-400 p-3'>{index + 1}</h1>
                  <img
                    src={item.img}
                    className='object-cover h-64 w-80 shadow-md shadow-black/35 rounded-lg'
                    alt=""
                  />
                </div>
              </div>
              <p className='text-center my-3 px-10 md:px-4 text-white text-lg md:text-2xl'>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      <ArchitectureBanner />


      {/* Achievements */}

      <div className=''>
        <h1 className="font-bold md:text-[45px] text-[30px] text-center text-black my-10  w-fit mx-auto">
          OUR ACHIEVEMENTS
        </h1>

        <div className="flex px-5 md:px-28 py-10 bg-[#01382A] text-yellow-500 justify-between">
          {/* 6180 */}
          <div>
            <NumberAnimation number={50} duration={2000} className='text-yellow-500 text-4xl md:text-6xl font-semibold'></NumberAnimation>
            <p className="md:text-4xl text-white text-base ">
              Interior Experts
            </p>
          </div>
          {/* 99 */}
          <div>
            <NumberAnimation number={60} duration={2500} className='text-yellow-500 text-4xl md:text-6xl font-semibold' ></NumberAnimation>
            <p className="md:text-4xl text-white text-base ">
              Location Served
            </p>
          </div>
          {/* 25k+ */}
          <div>
            <NumberAnimation number={1000} duration={2000} className='text-yellow-500 text-4xl md:text-6xl font-semibold'></NumberAnimation>
            <p className="md:text-4xl text-white text-base ">
              Happy Clients
            </p>
          </div>
        </div>
      </div>



      {/* Reviews */}

      <div className='md:px-24 px-5 pt-10'>
        <span className='flex flex-col items-center relative '>
          <h1 className="font-bold md:text-[55px] text-[35px] text-center  w-fit mx-auto">
            SUCCESFULL DESIGNS
          </h1>
          <span className='absolute top-0 md:top-5 rotate-3'>

            <svg className='' width='300px' xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 400"><path d="M107.34765625,218.10035705566406C107.22967539469401,215.6227590942383,67.98983225504557,208.2497043609619,106.45159912109375,199.2831573486328C144.91336598714193,190.3166103363037,319.9432287597656,159.91039485931395,399.46234130859375,150C478.9814538574219,140.08960514068605,669.4548612467448,127.43578144073486,710.3942260742188,124.01433563232422" fill="none" strokeWidth="16" stroke="hsl(50, 98%, 50%)" strokeLinecap="round" strokeDasharray="63 0"></path><defs><linearGradient id="SvgjsLinearGradient1004"><stop stopColor="hsl(37, 99%, 67%)" offset="0"></stop><stop stopColor="hsl(316, 73%, 52%)" offset="1"></stop></linearGradient></defs></svg>
          </span>
        </span>
        <div className='grid md:grid-cols-3 gap-10 grid-cols-1 mt-20 pb-10 justify-center'>
          <div className="flex justify-center">
            <iframe
              className="  md:w-96 w-full h-[300px] rounded-md shadow-lg"
              src="https://www.youtube.com/embed/qU6k8mNEtYg?si=YAPR3zE8vaUusAQt"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="flex w-full justify-center mx-auto ">
            <iframe
              className="  md:w-96 w-full h-[300px] rounded-md shadow-lg"
              src="https://www.youtube.com/embed/VIrmRdYkzAQ?si=50TN5NjKTzA-wFaH"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="flex w-full justify-center mx-auto ">
            <iframe
              className="  md:w-96 w-full h-[300px] rounded-md shadow-lg"
              src="https://www.youtube.com/embed/h9o-wzfJyz0?si=9I_4Od-gRiTu3nzG"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

      </div>

      <ArchitectureBanner />

      {/* Reviews */}
      <div>
        <h1 className="font-bold md:text-[60px] text-[35px] text-center py-5  w-fit mx-auto">
          OUR SATISFIED CUSTOMERS
        </h1>
        <CustomerReviews />
      </div>

      {/* FAQs */}
      <div className='md:px-24 px-5 py-10'>
        <h1 className="font-bold md:text-[45px] text-[28px] text-center mb-14  w-fit mx-auto">
          FAQs
        </h1>

        <div className='grid md:grid-cols-3 gap-20 grid-cols-1 md:px-auto px-5 justify-items-center'>
          {ArchitectureFAQs.map((item, index) =>
            <div key={index} className='shadow-[rgba(0,0,15,0.5)_10px_5px_4px_0px]'>
              <div className='relative bg-[#01382A] py-8 flex justify-center items-center '>
                <h1 className='text-center px-5 text-white'>{item.question}</h1>
              </div>
              <p className=' my-3 text-center px-10 text-black'>{item.answer}</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}


export default Architecture;
