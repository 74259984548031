const siteSupervisionData = {
  topSection: {
    headingText: "Build with",
    highlightedText: "confidence",
    subText: "Expert supervision for your dream project",
    headingText2: "",
    video: require('../assets/other-services/SiteSupervisionAudit/video.mp4')
  },

  features: [
    {
      title: 'On-site inspections',
      image: require('../assets/other-services/SiteSupervisionAudit/key-features/1.png')
    },
    {
      title: 'Issue identification and resolution',
      image: require('../assets/other-services/SiteSupervisionAudit/key-features/2.png')
    },
    {
      title: 'Coordination with contractors',
      image: require('../assets/other-services/SiteSupervisionAudit/key-features/3.png')
    },
    {
      title: 'Regular client updates',
      image: require('../assets/other-services/SiteSupervisionAudit/key-features/4.png')
    },
  ],

  numbers: [
    { number: 50, label: 'Interior Experts', symbol: '+', duration: 2500 },
    { number: 60, label: 'Location Served', symbol: '+', duration: 2500 },
    { number: 1000, label: 'Happy Clients', symbol: '+', duration: 2500 },
  ],

  stepsData: [
    {
      title: 'Initial Consultation',
      description: 'Understand project scope and client needs.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/1.png')
    },
    {
      title: 'Site Assessment',
      description: 'Conduct thorough site evaluation.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/2.png')
    },
    {
      title: 'Planning & Scheduling',
      description: 'Develop detailed supervision plan.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/3.png')
    },
    {
      title: 'On-Site Supervision',
      description: 'Monitor construction progress daily.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/4.png')
    },
    {
      title: 'Quality Inspections',
      description: 'Regular checks to ensure quality standards.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/5.png')
    },
    {
      title: 'Reporting',
      description: 'Provide comprehensive reports to clients.',
      image: require('../assets/other-services/SiteSupervisionAudit/how-it-works/6.png')
    },
  ],
  
  stepData: [
    {
      number:1,
      title: 'Initial Consultation',
      description: 'Understand project scope and client needs.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/1.png')
    },
    {
      number:2,
      title: 'Site Assessment',
      description: 'Conduct thorough site evaluation.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/2.png')
    },
    {
      number:3,
      title: 'Planning & Scheduling',
      description: 'Develop detailed supervision plan.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/3.png')
    },
    {
      number:4,
      title: 'On-Site Supervision',
      description: 'Monitor construction progress daily.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/4.png')
    },
    {
      number:5,
      title: 'Quality Inspections',
      description: 'Regular checks to ensure quality standards.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/5.png')
    },
    {
      number:6,
      title: 'Reporting',
      description: 'Provide comprehensive reports to clients.',
      icon: require('../assets/other-services/SiteSupervisionAudit/how-it-works/6.png')
    },
  ],

  faqs: [
    {
      question: 'How often should a site supervisor be present on the construction site?',
      answer:
        'A site supervisor should conduct regular site visits, at least weekly, to monitor progress, verify compliance, check quality, and be present for key milestones and inspections.',
    },
    {
      question: 'How does a site supervisor ensure construction site safety?',
      answer:
        'A site supervisor ensures site safety by verifying compliance with safety regulations, making sure workers have protective gear, identifying hazards, and promoting a safety culture through training and enforcement.',
    },
    {
      question: 'What is a quality audit in construction?',
      answer:
        'A quality audit is a structured, independent process to ensure project activities comply with organizational policies and procedures, preventing quality issues and suggesting improvements.',
    },
    {
      question: 'How does a site supervisor manage subcontractors on the project?',
      answer:
        'A site supervisor manages subcontractors by clearly defining responsibilities, holding regular meetings, monitoring performance, ensuring documentation, and verifying quality and schedule adherence.',
    },
  ],
  reviews: [
    {
      review: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
      name: "Mitali Sinha",
      avatar: require("../assets/testimonials/mitali-sinha.jpeg"),
      rating: 5
    },
    {
      review: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
      name: "P. Gowda",
      avatar: require("../assets/testimonials/p-gowda.jpeg"),
      rating: 4
    },
    {
      review: "They listened, they delivered. Impressed by their service.",
      name: "Nitin Prajapat",
      avatar: require("../assets/testimonials/nitin-prajapat.jpeg"),
      rating: 4
    },
    {
      review: "Fantastic architecture design & affordable.",
      name: "Snehal Raj",
      avatar: require("../assets/testimonials/snehal-raj.jpeg"),
      rating: 5
    },
  ],
  review: [
    {
      quote: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
      name: "Mitali Sinha",
      avatar: require("../assets/testimonials/mitali-sinha.jpeg"),
      rating: 5
    },
    {
      quote: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
      name: "P. Gowda",
      avatar: require("../assets/testimonials/p-gowda.jpeg"),
      rating: 4
    },
    {
      quote: "They listened, they delivered. Impressed by their service.",
      name: "Nitin Prajapat",
      avatar: require("../assets/testimonials/nitin-prajapat.jpeg"),
      rating: 4
    },
    {
      quote: "Fantastic architecture design & affordable.",
      name: "Snehal Raj",
      avatar: require("../assets/testimonials/snehal-raj.jpeg"),
      rating: 5
    },
  ]
};

export default siteSupervisionData;
