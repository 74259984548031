import React from "react";
import { Link } from "react-router-dom";
import { 
  FaFacebook, 
  FaTwitter, 
  FaInstagram, 
  FaLinkedin, 
  FaBlog, 
  FaYoutube, 
  FaPinterest 
} from 'react-icons/fa';

export default function Footer() {
  const socialLinks = [
    { icon: FaFacebook, url: "https://www.facebook.com/DesignElementary/", label: "Facebook" },
    { icon: FaInstagram, url: "https://www.instagram.com/designelementary_global/", label: "Instagram" },
    { icon: FaLinkedin, url: "https://in.linkedin.com/company/design-elementary", label: "LinkedIn" },
    { icon: FaPinterest, url: "https://in.pinterest.com/designelementary/", label: "Pinterest" },
    { icon: FaTwitter, url: "https://x.com/designelementa", label: "Twitter" },
    { icon: FaBlog, url: "https://www.designelementary.in/blogs/", label: "Blog" },
    { icon: FaYoutube, url: "https://www.youtube.com/channel/UC72ZYAc9_Vu9DPqxVSDsBiw/", label: "YouTube" }
  ];

  return (
    <footer className="bg-gray-50 border-t mb-12 md:mb-0">
      <div className="max-w-7xl md:max-w-[80%] mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-y-0 md:gap-x-6">
          {/* Logo and Description - More Width */}
          <div className="md:col-span-1 ">
            <Link to="/" className="inline-block mb-4">
              <img
                src={require("../assets/navlogo.svg").default}
                alt="Design Elementary"
                className="w-48 md:w-56"
              />
            </Link>
            <p className="text-gray-600 text-sm leading-snug max-w-md">
              Transforming spaces into extraordinary experiences  
              through innovative design solutions.
            </p>
          </div>

          {/* Company & Support - Reduced Gap */}
          <div className="md:col-span-1 grid grid-cols-2 gap-4 md:gap-8 md:ml-10">
            {/* Company Links */}
            <div>
              <h3 className="font-semibold text-gray-900 text-lg">Company</h3>
              <ul className="space-y-2 mt-2">
                {['About Us', 'Career', 'Execution Cities'].map((item) => (
                  <li key={item}>
                    <Link 
                      to={`/${item === 'About Us' ? 'about' : item === 'Execution Cities' ? 'cities' : item.toLowerCase().replace(' ', '-')}`}
                      className="text-gray-600 hover:text-gray-900 text-sm transition-colors duration-200"
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Support Links */}
            <div>
              <h3 className="font-semibold text-gray-900 text-lg">Support</h3>
              <ul className="space-y-2 mt-2">
                <li>
                  <Link to="/contact" className="text-gray-600 hover:text-gray-900 text-sm transition-colors duration-200">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/policies" className="text-gray-600 hover:text-gray-900 text-sm transition-colors duration-200">
                    Policies
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="text-gray-600 hover:text-gray-900 text-sm transition-colors duration-200">
                    T&C
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Social Media & Contact - Compact Layout */}
          <div className="md:col-span-1 md:ml-10">
            <h3 className="font-semibold text-gray-900 text-lg">Connect With Us</h3>
            <div className="flex flex-wrap gap-3 mt-2">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
                  aria-label={social.label}
                >
                  <social.icon className="w-5 h-5" />
                </a>
              ))}
            </div>
            <div className="pt-3">
              <p className="text-gray-600 text-sm">
                Email: care@designelementary.com
              </p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-10 pt-6 border-t border-gray-200">
          <p className="text-center text-gray-600 text-sm">
            &copy; {new Date().getFullYear()} Design Elementary. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
