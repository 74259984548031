import React, { useState } from 'react';

// Define the types for our FAQ item and props
interface FAQItem {
  question: string;
  answer: string;
}

interface MEPFaqProps {
  faqData: FAQItem[];
}

const FaqSection = ({ faqData }: MEPFaqProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full py-16 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <div className='max-w-[15rem] mx-auto'>
        <h2 className="text-4xl font-bold text-center mb-12">
          <div className="box a green-variant mb-12 p-3">
            <h1 className="text-4xl text-center font-semibold text-gray-900">FAQs</h1>
          </div>
          
        </h2>
        </div>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="rounded-lg overflow-hidden"
            >
              <button
                className={`w-full p-5 text-left flex justify-between items-center ${activeIndex === index
                    ? 'bg-[#0e906f] text-white'
                    : 'bg-[#002B3D] text-white'
                  } transition-colors duration-300`}
                onClick={() => toggleFAQ(index)}
              >
                <span className="text-lg font-semibold">{faq.question}</span>
                <svg
                  className={`w-5 h-5 transform transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''
                    }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div
                className={`transition-all duration-200 ease-in-out ${activeIndex === index
                    ? 'max-h-40 opacity-100'
                    : 'max-h-0 opacity-0'
                  } overflow-hidden`}
              >
                <div className="p-5 bg-white text-gray-600">
                  {faq.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;