import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const cards: Card[] = [
    {
      img: require("../../assets/architecture/layout/500-800/1.jpeg"),
      projectType: "residential",
      location: "Jaipur",
      length: 15,
      width: 35,
      floors: 1,
      direction: "North-West",
    },
    {
      img: require("../../assets/architecture/layout/500-800/2.jpeg"),
      projectType: "residential",
      location: "Kolkata",
      length: 20,
      width: 25,
      floors: 1,
      direction: "South",
    },
    {
      img: require("../../assets/architecture/layout/500-800/3.jpeg"),
      projectType: "residential",
      location: "Delhi",
      length: 20,
      width: 25,
      floors: 2,
      direction: "North",
    },
    {
      img: require("../../assets/architecture/layout/500-800/4.jpeg"),
      projectType: "residential",
      location: "Bangalore",
      length: 25,
      width: 20,
      floors: 2,
      direction: "North",
    },
    {
      img: require("../../assets/architecture/layout/500-800/5.jpeg"),
      projectType: "commercial",
      location: "Ranchi",
      length: 25,
      width: 20,
      floors: 2,
      direction: "South-East",
    },
    {
      img: require("../../assets/architecture/layout/500-800/6.jpeg"),
      projectType: "residential",
      location: "Ranchi",
      length: 25,
      width: 22,
      floors: 1,
      direction: "East",
    },
    {
      img: require("../../assets/architecture/layout/800-1200/1.jpeg"),
      projectType: "residential",
      location: "Coimbatore",
      length: 32,
      width: 32,
      floors: 1,
      direction: "North-East",
    },
    {
      img: require("../../assets/architecture/layout/800-1200/2.jpeg"),
      projectType: "commercial",
      location: "Pune",
      length: 32,
      width: 28,
      floors: 2,
      direction: "South-West",
    },
    {
      img: require("../../assets/architecture/layout/800-1200/3.jpeg"),
      projectType: "commercial",
      location: "Delhi",
      length: 30,
      width: 28,
      floors: 3,
      direction: "East",
    },
    {
      img: require("../../assets/architecture/layout/800-1200/4.jpeg"),
      projectType: "residential",
      location: "Dehradhun",
      length: 30,
      width: 30,
      floors: 2,
      direction: "North-East",
    },
    {
      img: require("../../assets/architecture/layout/800-1200/5.jpeg"),
      projectType: "residential",
      location: "Kolkata",
      length: 30,
      width: 30,
      floors: 3,
      direction: "East",
    },

    {
      img: require("../../assets/architecture/layout/1200-1600/1.jpeg"),
      projectType: "commercial",
      location: "Siliguri",
      length: 32,
      width: 38,
      floors: 2,
      direction: "West",
    },
    {
      img: require("../../assets/architecture/layout/1200-1600/2.jpeg"),
      projectType: "commercial",
      location: "Hyderabad",
      length: 25,
      width: 50,
      floors: 3,
      direction: "West",
    },
    {
      img: require("../../assets/architecture/layout/1200-1600/3.jpeg"),
      projectType: "commercial",
      location: "Ahmedabad",
      length: 40,
      width: 40,
      floors: 3,
      direction: "West",
    },
    {
      img: require("../../assets/architecture/layout/1200-1600/4.jpeg"),
      projectType: "commercial",
      location: "Siliguri",
      length: 35,
      width: 45,
      floors: 2,
      direction: "West",
    },
    {
      img: require("../../assets/architecture/layout/1200-1600/5.jpeg"),
      projectType: "commercial",
      location: "Hyderabad",
      length: 35,
      width: 40,
      floors: 3,
      direction: "West",
    },
    {
      img: require("../../assets/architecture/layout/1200-1600/6.jpeg"),
      projectType: "commercial",
      location: "Ahmedabad",
      length: 40,
      width: 50,
      floors: 3,
      direction: "West",
    },  {
        img: require("../../assets/architecture/layout/1600-2000/1.jpeg"),
        projectType: "residential",
        location: "Coimbatore",
        length: 40,
        width: 42,
        floors: 1,
        direction: "North-East",
      },
      {
        img: require("../../assets/architecture/layout/1600-2000/2.jpeg"),
        projectType: "commercial",
        location: "Pune",
        length: 32,
        width: 50,
        floors: 2,
        direction: "South-West",
      },
      {
        img: require("../../assets/architecture/layout/1600-2000/3.jpeg"),
        projectType: "commercial",
        location: "Delhi",
        length: 45,
        width: 40,
        floors: 3,
        direction: "East",
      },
      {
        img: require("../../assets/architecture/layout/1600-2000/4.jpeg"),
        projectType: "residential",
        location: "Dehradhun",
        length: 35,
        width: 55,
        floors: 2,
        direction: "North-East",
      },
      {
        img: require("../../assets/architecture/layout/1600-2000/5.jpeg"),
        projectType: "residential",
        location: "Kolkata",
        length: 48,
        width: 42,
        floors: 3,
        direction: "East",
      },
      {
        img: require("../../assets/architecture/layout/1600-2000/6.jpeg"),
        projectType: "residential",
        location: "Siliguri",
        length: 38,
        width: 50,
        floors: 3,
        direction: "East",
      },  {
        img: require("../../assets/architecture/layout/2000+/1.jpeg"),
        projectType: "residential",
        location: "Coimbatore",
        length: 45,
        width: 50,
        floors: 1,
        direction: "North-East",
      },
      {
        img: require("../../assets/architecture/layout/2000+/2.jpeg"),
        projectType: "commercial",
        location: "Pune",
        length: 40,
        width: 55,
        floors: 2,
        direction: "South-West",
      },
      {
        img: require("../../assets/architecture/layout/2000+/3.jpeg"),
        projectType: "commercial",
        location: "Delhi",
        length: 48,
        width: 50,
        floors: 3,
        direction: "East",
      },
      {
        img: require("../../assets/architecture/layout/2000+/4.jpeg"),
        projectType: "residential",
        location: "Dehradhun",
        length: 42,
        width: 52,
        floors: 2,
        direction: "North-East",
      },
      {
        img: require("../../assets/architecture/layout/2000+/5.jpeg"),
        projectType: "residential",
        location: "Kolkata",
        length: 35,
        width: 60,
        floors: 3,
        direction: "East",
      },
      {
        img: require("../../assets/architecture/layout/2000+/6.jpeg"),
        projectType: "residential",
        location: "Siliguri",
        length: 50,
        width: 50,
        floors: 3,
        direction: "East",
      },
  

  ];
  

interface Card {
  img: any;
  projectType: "residential" | "commercial";
  location: string;
  length: number;
  width: number;
  floors: number;
  direction: string;
  image?: string;
}

const Floorplan = () => {
  const [filteredPlans, setFilteredPlans] = useState<Card[]>(cards);
  const [lengthFilter, setLengthFilter] = useState<number | string>("");
  const [widthFilter, setWidthFilter] = useState<number | string>("");
  const [areaFilter, setAreaFilter] = useState<number | string>("");
  const [floorsFilter, setFloorsFilter] = useState<number | string>("");
  const [directionFilter, setDirectionFilter] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const handleFilter = () => {
    let filtered = cards;

    if (lengthFilter) {
      filtered = filtered.filter(
        (card) => card.length === Number(lengthFilter)
      );
    }

    if (widthFilter) {
      filtered = filtered.filter((card) => card.width === Number(widthFilter));
    }

    if (areaFilter) {
      filtered = filtered.filter(
        (card) => card.length * card.width === Number(areaFilter)
      );
    }

    if (floorsFilter) {
      filtered = filtered.filter(
        (card) => card.floors === Number(floorsFilter)
      );
    }

    if (directionFilter) {
      filtered = filtered.filter(
        (card) => card.direction.toLowerCase() === directionFilter.toLowerCase()
      );
    }

    setFilteredPlans(filtered);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />

      <div className="flex-grow flex mt-32">
        {/* Sidebar Button */}
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="md:hidden absolute w-14 z-50 p-1.5 h-10 top-32 left-0 flex rounded-r-full bg-gray-800 text-white"
        >
          {sidebarOpen ? "Close" : "Open"}
        </button>

        {/* Sidebar */}
        <div
          className={`md:w-1/4 w-full p-4 bg-base-200 rounded ${
            sidebarOpen ? "block" : "hidden md:block"
          }`}
        >
          <h1 className="font-black text-[1rem] md:text-[2rem] my-3 font-[Italiana] text-center">
            Floorplan
          </h1>
          <ul className="menu bg-base-100 divide-y rounded-box shadow">
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() => setFilteredPlans(cards)}
            >
              All
            </li>
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() =>
                setFilteredPlans(
                  cards.filter(
                    (card) =>
                      card.length * card.width >= 500 &&
                      card.length * card.width <= 800
                  )
                )
              }
            >
              500 Sqft - 800 Sqft
            </li>
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() =>
                setFilteredPlans(
                  cards.filter(
                    (card) =>
                      card.length * card.width > 800 &&
                      card.length * card.width <= 1200
                  )
                )
              }
            >
              800 Sqft - 1200 Sqft
            </li>
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() =>
                setFilteredPlans(
                  cards.filter(
                    (card) =>
                      card.length * card.width > 1200 &&
                      card.length * card.width <= 1600
                  )
                )
              }
            >
              1200 Sqft - 1600 Sqft
            </li>
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() =>
                setFilteredPlans(
                  cards.filter(
                    (card) =>
                      card.length * card.width > 1600 &&
                      card.length * card.width <= 2000
                  )
                )
              }
            >
              1600 Sqft - 2000 Sqft
            </li>
            <li
              className="py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white"
              onClick={() =>
                setFilteredPlans(
                  cards.filter((card) => card.length * card.width > 2000)
                )
              }
            >
              2000 Sqft and More
            </li>
          </ul>

          <h1 className="font-black text-[1rem] md:text-[2rem] my-3 font-[Italiana] text-center">
            Filter
          </h1>

          <div className="menu bg-base-100 divide-y rounded-box p-4 shadow">
            <div className="flex mb-2">
              <input
                type="number"
                placeholder="Length"
                value={lengthFilter}
                onChange={(e) => setLengthFilter(e.target.value)}
                className="input input-bordered w-full mr-2"
              />
              <input
                type="number"
                placeholder="Width"
                value={widthFilter}
                onChange={(e) => setWidthFilter(e.target.value)}
                className="input input-bordered w-full"
              />
            </div>
            <input
              type="number"
              placeholder="Area"
              value={areaFilter}
              onChange={(e) => setAreaFilter(e.target.value)}
              className="input input-bordered w-full mb-2"
            />
            <input
              type="number"
              placeholder="Floors"
              value={floorsFilter}
              onChange={(e) => setFloorsFilter(e.target.value)}
              className="input input-bordered w-full mb-2"
            />
            <input
              type="text"
              placeholder="Direction"
              value={directionFilter}
              onChange={(e) => setDirectionFilter(e.target.value)}
              className="input input-bordered w-full mb-2"
            />
            <button
              onClick={handleFilter}
              className="btn text-slate-100 bg-slate-700 w-full"
            >
              Search
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4 flex flex-wrap justify-center h-screen overflow-y-scroll scroll-hide bg-base-100">
          {filteredPlans.length > 0 ? (
            filteredPlans.map((plan, index) => (
              <div
                key={index}
                className="card max-h-[500px] w-80 flex-shrink bg-white hover:shadow-xl border  m-4"
              >
                <div className="card-body p-2">
                  {/* Image Placeholder */}
                  <div className="my-4 bg-gray-200 flex items-center justify-center">
                    <img
                      className="text-gray-400 h-38 object-cover"
                      src={plan.img}
                    />
                  </div>

                  {/* Plan Type and Location */}
                  <div className="flex justify-between bg-gray-800 text-white p-2 rounded">
                    <span className="text-sm">{plan.location}</span>
                    <span className="text-sm font-bold">
                      {plan.projectType.charAt(0).toUpperCase() +
                        plan.projectType.slice(1)}{" "}
                      Project
                    </span>
                  </div>

                  {/* Four Parameters */}
                  <div className="grid grid-cols-2 gap-2">
                    <div className="bg-gray-100 p-1 rounded text-center">
                      <p className="text-gray-600 text-sm">
                        {plan.length} ft X {plan.width} ft
                      </p>
                    </div>
                    <div className="bg-gray-100 p-1 rounded text-center">
                      <p className="text-gray-600 text-sm">
                        {plan.width * plan.length} sqft
                      </p>
                    </div>
                    <div className="bg-gray-100 p-1 rounded text-center">
                      <p className="text-gray-600 text-sm">
                        {plan.floors} Floor
                      </p>
                    </div>
                    <div className="bg-gray-100 p-1 rounded text-center">
                      <p className="text-gray-600 text-sm">{plan.direction} </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-lg">No plans found</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Floorplan;
