import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { UserErrorInterface } from '../interface/UserInterface';
import InputNumber from '../components/reusable-basic/InputNumber';
import FinalCostModel from '../components/cost-calculator/FinalCostModel';
import SendOtp from '../components/cost-calculator/SendOtp';
import { analytics, logEvent } from '../firebase/firebase';

const CostCalculator = () => {
    const floors = ["G", "G+1", "G+2", "G+3", "G+4", "G+5", "G+6", "G+7"];
    const directions = ["North", "South", "East", "West"];
    const categories = [
        { label: "Interior", rate: 21.49 },
        { label: "Architecture", rate: 13.00 },
    ];

    interface Field {
        name: string;
        label: string;
    }

    const fields: Field[] = [
        { name: "length", label: "Length" },
        { name: "width", label: "Width" },
    ];

    const [details, setDetails] = React.useState({
        length: "",
        width: "",
        buildingArea: "",
        floors: "",
        direction: "North",
        category: "Interior", // Default category
    });

    const [error, setError] = React.useState<UserErrorInterface>({
        message: "",
        hasError: false,
        field: "",
    });

    const [isOtpOpen, setIsOtpOpen] = React.useState(false);
    const [isCostModalOpen, setIsCostModalOpen] = React.useState(false);
    const [totalCost, setTotalCost] = React.useState(0);

    const validateField = (name: string, value: string): boolean => {
        let errorMsg = "";
        if (value === "") {
            errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        } else if (isNaN(Number(value)) && name !== "direction" && name !== "category") {
            errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} must be a number`;
        } else if (Number(value) <= 0 && name !== "direction" && name !== "category") {
            errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} must be greater than zero`;
        }

        setError({
            message: errorMsg,
            hasError: errorMsg !== "",
            field: name,
        });

        return errorMsg === "";
    };

    const handleChanges = (name: string, value: string): void => {
        if (
            name === "direction" ||
            name === "floors" ||
            name === "category" ||
            validateField(name, value)
        ) {
            setDetails((prevDetails) => {
                const newDetails = {
                    ...prevDetails,
                    [name]: value,
                };
                if (name === "length" || name === "width" || name === "floors") {
                    const length = parseFloat(newDetails.length) || 1;
                    const width = parseFloat(newDetails.width) || 1;
                    const floors = newDetails.floors.split("+")[1]
                        ? parseFloat(newDetails.floors.split("+")[1]) + 1
                        : 1;
                    newDetails.buildingArea = (length * width * floors).toString();
                }
                return newDetails;
            });
        }
    };

    const onCalculate = () => {
        logEvent(analytics, 'cost_calculated');
        setIsOtpOpen(true);
    };

    const displayCost = async () => {
        try {
            const totalSqft = parseFloat(details.buildingArea);
            const selectedCategory = categories.find(
                (category) => category.label === details.category
            );
            const rate = selectedCategory?.rate || 0;
            const calculatedCost = totalSqft * rate;
            setTotalCost(calculatedCost);
            setIsCostModalOpen(true);
        } catch (error) {
            console.error("Error calculating cost:", error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className='flex w-full flex-col pt-20 md:pt-28 md:flex-row px-4 md:px-10'>
                <div className="bg-[#048F70] rounded-3xl md:rounded-l-3xl md:rounded-r-none shrink-0 flex-grow w-full md:w-1/2 p-4 md:p-8 mb-6 md:mb-0">
                    <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-[#F4FFFF] mt-4 text-center">Welcome to the Cost Calculator</h2>
                    <p className="text-sm md:text-base mb-4 md:mb-6 text-[#F4FFFF] text-center">
                        Calculate the cost for your interior and architectural projects effortlessly. Each point below highlights the benefits of our calculator.
                    </p>
                    <div className="grid grid-cols-2 gap-3 md:gap-4">
                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                ✨
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Explore Your Design Options</h3>
                                <p className="text-xs md:text-sm text-green-700">Select from a variety of designs.</p>
                            </div>
                        </div>

                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                🛠
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Multi-Floor Calculations</h3>
                                <p className="text-xs md:text-sm text-green-700">Calculate multiple floors at once.</p>
                            </div>
                        </div>

                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                📐
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Accurate Dimensions</h3>
                                <p className="text-xs md:text-sm text-green-700">Ensure precision in all your measurements.</p>
                            </div>
                        </div>

                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                🎨
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Interior Design</h3>
                                <p className="text-xs md:text-sm text-green-700">Cost: <span className=' font-bold text-yellow-500'>₹21.49/sq. ft.</span>  for stunning interiors.</p>
                            </div>
                        </div>

                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                🏛
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Architectural Design</h3>
                                <p className="text-xs md:text-sm text-green-700">Cost: <span className=' font-bold text-yellow-500'>₹12.99/sq. ft.</span>  for top-notch plans.</p>
                            </div>
                        </div>

                        <div className="card shadow-lg p-3 md:p-4 flex items-center bg-white border-l-4 border-[#1A454C]">
                            <div className="flex-shrink-0 text-green-500 text-xl md:text-2xl mr-2 md:mr-4">
                                💡
                            </div>
                            <div className="text-center">
                                <h3 className="text-sm md:text-lg font-semibold text-green-800">Maximize Precision</h3>
                                <p className="text-xs md:text-sm text-green-700">Get exact cost estimates for your dream project.</p>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    id="#cost-calculator-section"
                    className="shrink-0 md:w-1/2 w-full flex-grow pb-6 md:pb-10 relative border-gray-200 bg-gradient-to-tr bg-slate-50 rounded-3xl md:rounded-l-none md:rounded-r-lg border"
                >
                    <span className="flex items-center border-b justify-center gap-2 md:gap-3 py-2 md:py-3 mb-4 md:mb-5 text-xl md:text-2xl font-semibold">
                        <h1 className="">Cost Calculator</h1>
                        <img
                            src={require("../assets/calc.png")}
                            className="h-12 md:h-16 p-2 md:p-3"
                            alt=""
                        />
                    </span>
                    <div className="grid grid-cols-1 gap-3 md:gap-4 px-4 sm:px-10 md:px-20">
                        {fields.map((field) => (
                            <InputNumber
                                key={field.name}
                                defValue={details[field.name]?.toString() || ""}
                                inputClassName=""
                                onChangeHandler={handleChanges}
                                name={field.name}
                                placeholder={field.label + " in ft"}
                                disabled={false}
                                error={
                                    error.field === field.name && error.hasError ? error.message : ""
                                }
                            />
                        ))}

                        <select
                            id="floors"
                            name="floors"
                            value={details.floors}
                            onChange={(e) => handleChanges("floors", e.target.value)}
                            className="input rounded-lg my-2"
                        >
                            <option key="Select Floors" value="">
                                Select Floors
                            </option>
                            {floors.map((floor) => (
                                <option key={floor} value={floor}>
                                    {floor}
                                </option>
                            ))}
                        </select>

                        <InputNumber
                            key="buildingArea"
                            defValue={details.buildingArea || ""}
                            inputClassName=""
                            onChangeHandler={() => { }}
                            name="buildingArea"
                            placeholder="Building Area (sq. ft.)"
                            disabled={true}
                            error=""
                        />

                        <select
                            id="category"
                            name="category"
                            value={details.category}
                            onChange={(e) => handleChanges("category", e.target.value)}
                            className="input rounded-lg my-2"
                        >
                            {categories.map((category) => (
                                <option key={category.label} value={category.label}>
                                    {category.label}
                                </option>
                            ))}
                        </select>

                        <select
                            id="direction"
                            name="direction"
                            value={details.direction}
                            onChange={(e) => handleChanges("direction", e.target.value)}
                            className="input rounded-lg my-2"
                        >
                            {directions.map((direction) => (
                                <option key={direction} value={direction}>
                                    {direction}
                                </option>
                            ))}
                        </select>

                        <button
                            onClick={onCalculate}
                            className="py-2 my-1.5 flex w-full items-center justify-center rounded-lg bg-[#1A454C] hover:bg-[#048F70] transition-colors text-[14px] md:text-[16px] font-[700] text-[#f8f9f9]"
                        >
                            Calculate
                        </button>
                    </div>

                    {isOtpOpen && (
                        <SendOtp
                            onSuccess={displayCost}
                            onClose={() => setIsOtpOpen(false)}
                        />
                    )}

                    {isCostModalOpen && (
                        <FinalCostModel
                            details={details}
                            totalCost={totalCost}
                            onClose={() => setIsCostModalOpen(false)}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CostCalculator;
