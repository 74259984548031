import React from "react";
import { motion } from "framer-motion";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

const OurExp = ({ expertiseData }) => {
  const scrollRef = useInfiniteScroll();

  return (
    <div className="w-full py-16 bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <div className="max-w-[16rem] md:max-w-[22rem] mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            <div className="box a green-variant mb-12 p-3">
              <h1 className="text-2xl md:text-4xl text-center font-semibold text-gray-900">
                Our Expertise
              </h1>
            </div>
          </h2>
        </div>

        {/* Scrolling Section */}
        <div className="relative w-full overflow-hidden">
          {/* Shadow Overlays */}
          <div className="absolute left-0 top-0 w-24 h-full bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 w-24 h-full bg-gradient-to-l from-white to-transparent z-10"></div>

          {/* Infinite Scroll Container */}
          <div ref={scrollRef} className="flex overflow-hidden">
            <div className="flex animate-seamless-scroll">
              {/* First Set */}
              <div className="flex-none flex gap-8">
                {Array.from({ length: expertiseData.length * 2 }).map(
                  (_, i) => {
                    const index = i % expertiseData.length; // Loop through the elements
                    return (
                      <ExpertiseCard
                        key={`set1-${i}`}
                        item={expertiseData[index]}
                      />
                    );
                  }
                )}
              </div>

              {/* Duplicate Set for Seamless Scrolling */}
              <div className="flex-none flex gap-8 ml-8">
                {Array.from({ length: expertiseData.length * 2 }).map(
                  (_, i) => {
                    const index = i % expertiseData.length; // Loop through the elements
                    return (
                      <ExpertiseCard
                        key={`set2-${i}`}
                        item={expertiseData[index]}
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpertiseCard = ({ item }) => {
  return (
    <motion.div className="flex flex-col items-center text-center group w-64">
      <div className="w-24 h-24 mb-6 transition-transform duration-300 group-hover:scale-110">
        <img
          src={item.image}
          alt={item.title}
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-lg font-semibold text-gray-800 max-w-[200px]">
        {item.title}
      </h3>
    </motion.div>
  );
};

export default OurExp;
