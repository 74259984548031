import React from "react"
import Header from "../../components/Header"
import StructureDesigns from "../../components/structure/StructureDesign"
import CustomerSaysOfStructure from "../../components/structure/CustomerSays"
import StructreFaq from "../../components/structure/StructureFAQ"
import WhyChooseUsStructure from "../../components/structure/WhyChooseUs"
import HowItWorksStructure from "../../components/structure/HowitWorks"
import StructureTopSec from "../../components/structure/StructureTopSec"
import OurExpStructure from "../../components/structure/OurExprt"


const StructurePage = () =>{
    return <>
    <Header/>
    <StructureTopSec/>
    <StructureDesigns/>
    <OurExpStructure/>
    <HowItWorksStructure/>
    <WhyChooseUsStructure/>
    <CustomerSaysOfStructure/>
    <StructreFaq/>
    </>
}
export default StructurePage