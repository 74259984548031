export const WalkVids = [
    require('../assets/Walkthrough/banner_image/1.mp4'),
    require('../assets/Walkthrough/banner_image/2.mp4'),
    require('../assets/Walkthrough/banner_image/3.mp4')
];
export const WalkIcons = {
    Approved: require('../assets/Walkthrough/banner_icon/300_Approved_Projects.png'),
    Commercial: require('../assets/Walkthrough/banner_icon/300_Commercial_Walks.png'),
    Residential: require('../assets/Walkthrough/banner_icon/650_Residential_Tours.png')
};

export const OurExp = [
    require('../assets/Walkthrough/Our_expertise_image/Customizable_Design_Options.png'),
    require('../assets/Walkthrough/Our_expertise_image/Interior_and_Exterior_3D_Views.png'),
    require('../assets/Walkthrough/Our_expertise_image/Residential_and_Commercial_Walkthroughs.png'),
    require('../assets/Walkthrough/Our_expertise_image/Virtual_Reality_VR_Integration.png')
];



