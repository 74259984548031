import React, { useState } from 'react';
import HowItWorks from '../UI/HowItsWorks';

const HowItWorksPlan = () => {
    

    const steps = [
        
        {
            number: 1,
            title: "Project Consultation",
            icon: require('../../assets/PlanApproval/HOW_IT_WORKS_icon/Consultation_and_Project_Brief.png'),
            description: ""
        },
        {
            number: 2,
            title: "Document Preparation & Submission",
            icon: require('../../assets/PlanApproval/HOW_IT_WORKS_icon/Document_Preparation_and_Submission.png'),
            
            description: ""
        },
        
        {
            number: 3,
            title: "Review & Compliance Check",
            icon: require('../../assets/PlanApproval/HOW_IT_WORKS_icon/Review_and_Compliance_Check.png'),
            description: ""
        },
        {
            number: 4,
            title: "Follow-Up & Revisions",
            icon: require('../../assets/PlanApproval/HOW_IT_WORKS_icon/Follow_Up_and_Revisions.png'),
            
            description: ""
        },
        {
            number: 5,
            title: "Approval & Handover",
            icon: require('../../assets/PlanApproval/HOW_IT_WORKS_icon/Approval_and_Handover.png'),
            description: ""
        },
    ];


    return (
        <><HowItWorks steps={steps}/></>
    );
};

export default HowItWorksPlan;
