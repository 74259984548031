import React from "react"
import Header from "../../components/Header"
import WalkTopSec from "../../components/WalkThrough/TopSectionForWalkThrought"
import WhyChooseUsWalk from "../../components/WalkThrough/WhychsUsforWalk"
import OurExpWalk from "../../components/WalkThrough/OurExpforWalk"
import HowItWorksWalk from "../../components/WalkThrough/HowItsWorksforWalk"
import CustomerSaysOfWalk from "../../components/WalkThrough/custSyasforWalk"
import WalkFaq from "../../components/WalkThrough/FAQforWalk"


const WalkThroughPage = () => {
    return (
        <>
            <Header />
            <WalkTopSec />
            <OurExpWalk />
            <HowItWorksWalk />
            <WhyChooseUsWalk />
            <CustomerSaysOfWalk />
            <WalkFaq />
        </>
    )
}
export default WalkThroughPage