import React from 'react';
import { OurExp as ExpertiseImages } from '../../constants/plan-approval';
import OurExp from '../UI/OurExp';

const OurExpPlan = () => {
    const expertiseData = [
        {
            title: "Residential & Commercial Plan Approvals",
            image: ExpertiseImages[3]
        },
        {
            title: "Municipal & Government Compliance",
            image: ExpertiseImages[2]
        },
        {
            title: "Legal & Safety Regulations",
            image: ExpertiseImages[1]
        },
        {
            title: "Document & Drawing Verification",
            image: ExpertiseImages[0]
        },
        
        
        
    ];

    return (
        <OurExp expertiseData={expertiseData}/>
    );
};

export default OurExpPlan;
