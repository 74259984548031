export const GalleryImages = {
    interior: {
        commercial: [...Array(47)].map((_, index) => ({
            name: 'Commercial Interior',
            image: require(`../assets/gallery/interior/commercial/${index + 1}.jpg`) // Spaces are preserved in the path
        })),
        
        residential: [...Array(45)].map((_, index) => ({
            name: 'Residential Interior',
            image: require(`../assets/gallery/interior/residential/${index + 1}.jpg`) // Spaces are preserved in the path
        })),
    },
    architecture: {
        commercial: [...Array(32)].map((_, index) => ({
            name: 'Commercial Architecture',
            image: require(`../assets/gallery/architecture/commercial/${index + 1}.jpg`) // Spaces are preserved in the path
        })),
        
        residential: [...Array(45)].map((_, index) => ({
            name: 'Residential Architecture',
            image: require(`../assets/gallery/architecture/residential/${index + 1}.jpg`) // Spaces are preserved in the path
        }))
    }
};
